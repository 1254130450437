import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import Header from "../Components/Header";
import SideBarEliteRole from "../Ellite_Role/SideBarEliteRole";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PackageRole = () => {
  const [weekDays, setWeekDays] = useState([]);
  const [categories, setCategories] = useState([]);

  const [formData, setFormData] = useState({
    CATEGORY: "",
    WEEK_DAYS: "",
    PACKAGE_NAME: "",
    SPORT_TYPE: "",
    DURATION: "",
    PRICE: "",
    STATUS: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/package`, formData);
      console.log(response.data);
      console.log("successfully inserted");
      window.alert("successfully submitted");
    } catch (error) {
      console.error("Error submitting form:", error);
      window.alert("unable to submit");
    }
  };

  // update the input values to the variable
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/products`)
      .then((response) => {
        const { package_name, package_date, category_data } = response.data;

        const batchNames = package_date.map((entry) => entry.PACKAGE_BATCH);
        const packageName = package_name.map((entry) => entry.PACKAGE_NAME);
        const categoryName = category_data.map((entry) => entry.CATEGORY);

        setWeekDays(batchNames);
        // setPackageName(packageName);
        // setCategoryData(categoryName);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleChangeWeekDay = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      WEEK_DAYS: newValue,
    }));
  };

  // Fetch categories from the backend when the component mounts
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/categories`)
      .then((response) => {
        const categoryNames = response.data; // Assuming the response directly contains category names
        setCategories(categoryNames);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const title = "Package Details";

  // start of the creation of UI
  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBarEliteRole />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Package Details
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Category"
                    name="CATEGORY"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={weekDays}
                    name="WEEK_DAYS"
                    // sx={{ width: }}
                    onChange={handleChangeWeekDay}
                    renderInput={(params) => (
                      <TextField {...params} label="Week Days" />
                    )}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Package Name"
                    name="PACKAGE_NAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Sport Type"
                    name="SPORT_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Duration"
                    name="DURATION"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="PRICE"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      onChange={handleChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <br />

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: "20%", backgroundColor: "#306ef3" }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PackageRole;
