import React, { useState, useEffect } from "react";
import "./../ComponentsCSS/PopUp.css";
import { TextField, Button, Grid, Autocomplete } from "@mui/material";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const PopUpForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    NAME: "",
    emp_code: "",
    TIME_SLOT: "",
    court_id:"",
    date: getCurrentDate(), // Initialize with current date
    checkIn: "",
    checkOut: "",
    Remarks: "",
  });

  const [studentOptions, setStudentOptions] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  useEffect(() => {
    // Fetch student options for the autocomplete field
    axios
      .get(`${API_BASE_URL}/fetchskystudent`)
      .then((response) => {
        setStudentOptions(response.data.map((entry) => entry.NAME));
      })
      .catch((error) => {
        console.error("Error fetching student options:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (event, newValue) => {
    let NAME = newValue !== null ? newValue : "";

    // Update the form data with the selected name
    setFormData((prevData) => ({
      ...prevData,
      NAME: NAME,
    }));

    // Fetch details only when NAME is not empty
    if (NAME.trim() !== "") {
      axios
        .post(`${API_BASE_URL}/Sky_Manual`, { NAME })
        .then((response) => {
          const { emp_code, TIME_SLOT } = response.data;
          setFormData((prevData) => ({
            ...prevData,
            emp_code,
            TIME_SLOT,
          }));
          handleTimeSlotChange(TIME_SLOT); // Update the time slot change
        })
        .catch((error) => {
          console.error("Error fetching student data:", error);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);

    axios
      .post(`${API_BASE_URL}/Sky_Manual/attendance`, formData)
      .then((response) => {
        console.log(response.data);
        window.alert("Successfully submitted");
        onClose();
      })
      .catch((error) => {
        console.log(error);
        window.alert("Error submitting data");
      });

      
    const { NAME, court_id, TIME_SLOT, date } = formData;

    // Send only the required fields to the backend
    const payload = {
      student: NAME,
      court_id: court_id,
      timeslot: TIME_SLOT,
      date: date,
    };

    axios
      .post(`${API_BASE_URL}/assign`, payload)
      .then((response) => {
        console.log(response.data);
        window.alert("Successfully submitted");
        onClose();
      })
      .catch((error) => {
        console.log(error);
        window.alert("Error submitting data");
      });
  };

  const handleTimeSlotChange = (newValue) => {
    if (newValue) {
      // Extracting the time part from the time slot
      const timeParts = newValue.split(" - ");
      if (timeParts.length === 2) {
        const endTime = timeParts[1]; // Extracting the end time
        // Format the end time to remove AM/PM
        const formattedEndTime = endTime.replace(/AM|PM/g, "").trim();
        setFormData((prevData) => ({
          ...prevData,
          TIME_SLOT: newValue,
          checkOut: formattedEndTime,
        }));
      }
    }
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Adding leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>Add Attendance</h2>
          <button onClick={() => onClose(false)} className="close-modal-btn">
            &times;
          </button>
        </div>
        <div className="modal-content">
          <form id="form" onSubmit={handleSubmit}>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={studentOptions}
                  value={formData.NAME}
                  onChange={handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Student Name" />
                  )}
                  freeSolo
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  label="Student Code"
                  name="emp_code"
                  value={formData.emp_code}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  label="Time Slot"
                  name="TIME_SLOT"
                  value={formData.TIME_SLOT}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  label="Court"
                  name="court_id"
                  value={formData.court_id}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  label="Check In"
                  name="checkIn"
                  value={formData.checkIn}
                  onChange={handleChange}
                  variant="outlined"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  label="Check Out"
                  name="checkOut"
                  type="time"
                  value={formData.checkOut}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  label="Remarks"
                  name="Remarks"
                  value={formData.Remarks}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopUpForm;
