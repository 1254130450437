import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUser, FaUsers, FaFile } from "react-icons/fa";
import elite from "../Components/elite1.png";
import skyline from "../Components/skyline.png";
import SideBar1 from "../Components/SideBar1";
import Header1 from "../Components/Header1";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from "recharts";
import "../SecondPage/SecondCSS/Dashboard.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Courts from "../Components/Courts";
import { Link } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function DashBoard1({ selectedLogo }) {
  const [totalStudents, setTotalStudents] = useState(0);
  const [activeStudents, setActiveStudents] = useState(0);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [gaugeValue, setGaugeValue] = useState(0);
  const [monthlyEnrollments, setMonthlyEnrollments] = useState(0);
  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");
  const [displayedValues, setDisplayedValues] = useState("");

  useEffect(() => {
    console.log("selectedLogo in Dashboard:", selectedLogo);
  }, [selectedLogo]);

  // to get the total students , active students, current_month count
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/sky_dashboard/getSkyStudentCounts`
        );
        const { total_students, active_students, current_month_count } =
          response.data;
        setTotalStudents(total_students);
        setActiveStudents(active_students);
        setMonthlyEnrollments(current_month_count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function getCurrentMonthYear() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Adjust month to be zero-indexed

    return `${year}-${month}`;
  }
  const selectedMonth = getCurrentMonthYear();

  return (
    <main className="main-container">
      <Header1 title="Dashboard" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar1 selectedLogo={selectedLogo} />
        <div className="main-cards">
          <Courts />

          <div className="row-cards">
            <Link to="/ManageMembers1">
              <div className="card" style={{ color: "#524e4ef2" }}>
                <div className="card-inner">
                  <h3>Student Data</h3>
                  <FaUser className="card_icon" />
                </div>
                <h1>{totalStudents}</h1>
              </div>
            </Link>
            <Link to="/ManageMembers1/active">
              <div className="card" style={{ color: "#524e4ef2" }}>
                <div className="card-inner">
                  <h3> Active Student</h3>
                  <FaFile className="card_icon" />
                </div>
                <h1>{activeStudents}</h1>
              </div>
            </Link>
            <Link to={`/ManageMembers/${selectedMonth}`}>
              <div className="card" style={{ color: "#524e4ef2" }}>
                <div className="card-inner">
                  <h3>Monthly Enrollments</h3>
                  <FaFile className="card_icon" />
                </div>
                <h1>{monthlyEnrollments}</h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DashBoard1;
