import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || ""); // Retrieve token from localStorage initially

  const login = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken); // Store token in localStorage
    Cookies.set("token", newToken, { expires: 1, sameSite: "strict" });
    // console.log("Login: Token set to", newToken);
  };

  const logout = () => {
    // Remove token from state, cookies, and local storage
    setToken("");
    Cookies.remove("token");
    localStorage.removeItem("token");
    // console.log("Logout: Token removed");
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      // console.log(
      //   "Effect: Token found in localStorage and set to state:",
      //   storedToken
      // );
    } else {
      // console.log("Effect: No token found in localStorage");
    }
  }, []);

  // useEffect(() => {
  //   // console.log("Token state updated:", token);
  // }, [token]);

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
