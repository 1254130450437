import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import { useLocation } from "react-router-dom";
import SideBar1 from "../Components/SideBar1";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StudentData1 = () => {
  const location = useLocation();
  const selectedLogo = new URLSearchParams(location.search).get("selectedLogo");

  const [options, setOptions] = useState([]);
  const [number, setNumber] = useState("");
  const [packages, setPackages] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const [duration, setDuration] = useState([]);
  const [empCode, setEmpCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [formData, setFormData] = useState({
    EMP_CODE: "",
    NAME: "",
    SURNAME: "",
    SEX: "",
    AGE: "",
    PHONE_NUMBER: "",
    GUARDIAN_NAME: "",
    GUARDIAN_PHONE_NUMBER: "",
    RELATIONSHIP: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    STATUS: "",
    PAUSE_START_DATE: null,
    PAUSE_END_DATE: null,
    COMMENTS: "",
    PACKAGE_TYPE: "",
    TIME_SLOT: "",
    DURATION: "",
    SPORT_TYPE: "Badminton",
    PRICE: "",
    ID: "",
    PACKAGE_ID: "",
    COURT: "",
    BULK_HOURS_ALLOCATED: "",
    BULK_HOURS_USED: "",
    BULK_PRICE: "",
    START_DATE: "",
    END_DATE: "",
    RENEWED: "",
    DISCOUNT: "",
    TOTAL_AMOUNT: "",
  });

  const [courts, setCourts] = useState({
    court_1: false,
    court_2: false,
    court_3: false,
    court_4: false,
    court_5: false,
  });

  // Fetch data from Express.js backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetch_package`)
      .then((response) => {
        const { packages } = response.data;
        const packageTypes = packages.map((pkg) => pkg.package_type);
        const timeSlot = packages.map((pkg) => pkg.time_slot);
        const duration = packages.map((pkg) => pkg.duration);

        setPackages(packages);

        setPackageType([...new Set(packageTypes)]); // Ensure uniqueness
        setDuration([...new Set(duration)]); // Ensure uniqueness
        setTimeSlot([...new Set(timeSlot)]); // Ensure uniqueness
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "START_DATE" || name === "DURATION") {
      const startDate =
        name === "START_DATE" ? new Date(value) : new Date(formData.START_DATE);
      const duration =
        name === "DURATION" ? parseInt(value) : parseInt(formData.DURATION);

      if (!isNaN(startDate.getTime()) && !isNaN(duration)) {
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + duration);

        // Subtract one day from the end date
        endDate.setDate(endDate.getDate() - 1);

        setFormData((prevFormData) => ({
          ...prevFormData,
          END_DATE: endDate.toISOString().split("T")[0],
        }));
      }
    }

    if (name === "DISCOUNT" || name === "PRICE") {
      const price = parseFloat(name === "PRICE" ? value : formData.PRICE);
      const discount = parseFloat(
        name === "DISCOUNT" ? value : formData.DISCOUNT
      );
      if (!isNaN(price) && !isNaN(discount)) {
        const totalAmount = price - discount;
        setFormData((prevFormData) => ({
          ...prevFormData,
          TOTAL_AMOUNT: totalAmount.toFixed(2),
        }));
      }
    }

    if (name === "DISCOUNT" || name === "BULK_PRICE") {
      const price = parseFloat(
        name === "BULK_PRICE" ? value : formData.BULK_PRICE
      );
      const discount = parseFloat(
        name === "DISCOUNT" ? value : formData.DISCOUNT
      );
      if (!isNaN(price) && !isNaN(discount)) {
        const totalAmount = price - discount;
        setFormData((prevFormData) => ({
          ...prevFormData,
          TOTAL_AMOUNT: totalAmount.toFixed(2),
        }));
      }
    }

    if (name === "STATUS") {
      const showDates = value === "Pause";
      setFormData((prevFormData) => ({
        ...prevFormData,
        STATUS: value,
      }));
      setShowDates(showDates);
    }

    if (name === "PHONE_NUMBER") {
      setPhoneNumber(value);

      if (value.length > 10 || value.length < 10) {
        setPhoneNumberError(true);
      } else {
        setPhoneNumberError(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted, preventing default action");

    try {
      console.log("Form Data on Submit: ", formData);
      const response = await axios.post(
        `${API_BASE_URL}/sky_student`,
        formData
      );
      console.log("Response from student API:", response.data);
      window.alert("Successfully inserted the student data");

      const downloadPdf = async (endpoint, fileName) => {
        try {
          const res = await axios.post(
            `${API_BASE_URL}/${endpoint}`,
            { formData },
            { responseType: "blob" }
          );
          console.log(`PDF download request successful for ${fileName}`);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          console.log(`${fileName} downloaded successfully`);
        } catch (error) {
          console.error(`Error downloading ${fileName}:`, error);
        }
      };

      await downloadPdf(
        "pdfskystudent",
        `${formData.NAME} BillDetails.pdf`
      );
      await downloadPdf(
        "pdfSkyMembershipStudent",
        `${formData.NAME}MembershipDetails.pdf`
      );
      window.location.reload();
    } catch (error) {
      console.error("Error inserting data:", error);
      window.alert("Failed to insert the data");
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []); // Fetch options on component mount

  const fetchOptions = () => {
    axios
      .get(`${API_BASE_URL}/api/options`)
      .then((response) => {
        console.log("Response:", response.data); // Log the response data
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error); // Log any errors
      });
  };

  const handleChangePackageType = async (event, newValue) => {
    try {
      setFormData((prevData) => ({
        ...prevData,
        PACKAGE_TYPE: newValue,
        TIME_SLOT: "", // Reset TIME_SLOT when PACKAGE_TYPE changes
        DURATION: "", // Reset DURATION when PACKAGE_TYPE changes
        PRICE: "", // Reset PRICE when PACKAGE_TYPE changes
        ID: newValue === "BULK" ? "1" : prevData.ID,
        PACKAGE_ID: newValue === "BULK" ? "1" : prevData.PACKAGE_ID,
      }));

      const response = await axios.post(`${API_BASE_URL}/api/package-type`, {
        packageType: newValue,
      });

      // const { data } = response;
      // setTimeSlot(data);

      const timeSlotData = response.data;
      setTimeSlot(timeSlotData);
    } catch (error) {
      console.error("Error fetching package names:", error);
    }

    // Send data to server if the package type is not BULK
    if (newValue !== "BULK") {
      sendDataToServer();
    }
  };

  const handleChangeTimeSlot = async (event, newValue) => {
    // Adjust the newValue if it's '5:30 - 7:00 AM'
    const adjustedTimeSlot =
      newValue === "5:30 - 7:00 AM" ? "6 - 7 AM" : newValue;

    setFormData((prevData) => ({
      ...prevData,
      TIME_SLOT: adjustedTimeSlot, // Keep the displayed value as selected
      DURATION: "", // Reset DURATION when TIME_SLOT changes
      PRICE: "", // Reset PRICE when TIME_SLOT changes
    }));

    // Fetch duration based on selected time slot and package type
    try {
      const response = await axios.post(`${API_BASE_URL}/api/timeSlot`, {
        packageType: formData.PACKAGE_TYPE,
        timeSlot: adjustedTimeSlot, // Send the adjusted time slot to the server
      });

      const durationData = response.data;
      setDuration(durationData);
    } catch (error) {
      console.error("Error fetching duration data:", error);
    }

    // Send data to the server if the package type is not BULK
    if (formData.PACKAGE_TYPE !== "BULK") {
      sendDataToServer();
    }
  };

  const handleChangeDuration = async (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      DURATION: newValue,
      PRICE: "", // Reset PRICE when DURATION changes
    }));

    // Send data to server if the package type is not BULK
    if (formData.PACKAGE_TYPE !== "BULK") {
      sendDataToServer();
    }
  };

  const sendDataToServer = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/sky_price`, formData);

      const { price, ID } = response.data;

      console.log("get price :", price);
      console.log("get ID :", ID);

      setFormData((prevFormData) => ({
        ...prevFormData,
        PRICE: price,
        ID: ID,
        PACKAGE_ID: ID,
      }));
    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  };

  const fetchCourtData = async () => {
    console.log("fetchCourtData ");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/sky_courts`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      const data = response.data;
      setCourts(data); // Update the state with the server response
    } catch (error) {
      console.error("Error fetching court data:", error);
    }
  };

  // Example usage
  useEffect(() => {
    fetchCourtData();
  }, [formData]);

  // fetch the emp code and provide auto increment
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetchEmpCodeSky`)
      .then((response) => {
        console.log("Response data:", response.data);

        const lastEmpCodeString = response.data[0].emp_code;
        console.log("Last emp_code string:", lastEmpCodeString);

        const lastEmpCode = parseInt(lastEmpCodeString);
        console.log("Parsed last emp_code:", lastEmpCode);

        const nextEmpCode = lastEmpCode + 1;
        console.log("Next emp_code:", nextEmpCode);

        setEmpCode(nextEmpCode.toString());
        setFormData((prevFormData) => ({
          ...prevFormData,
          EMP_CODE: nextEmpCode.toString(),
        }));
      })
      .catch((error) => {
        console.error("Error fetching employee codes:", error);
      });
  }, []);

  const handleChangeCurrentEmpCode = (event) => {
    setEmpCode(event.target.value);
    setFormData({
      ...formData,
      EMP_CODE: event.target.value,
    });
  };

  useEffect(() => {
    if (formData.PACKAGE_TYPE && formData.TIME_SLOT && formData.DURATION) {
      sendDataToServer();
    }
  }, [formData.PACKAGE_TYPE, formData.TIME_SLOT, formData.DURATION]);

  console.log(formData);

  const [showDates, setShowDates] = useState(false);

  const title = "Student Details";

  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar1 />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="ID"
                    variant="outlined"
                    name="EMP_CODE"
                    value={empCode}
                    onChange={handleChangeCurrentEmpCode}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">BD</InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="NAME"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="SURNAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel required>Gender</InputLabel>
                    <Select
                      label="Gender"
                      name="SEX"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Age"
                    name="AGE"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    value={phoneNumber}
                    error={phoneNumberError}
                    helperText={phoneNumberError ? "Invalid Entry." : ""}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian Name"
                    name="GUARDIAN_NAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian PhNo."
                    name="GUARDIAN_PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Relation</InputLabel>
                    <Select
                      label="Relation"
                      name="RELATIONSHIP"
                      onChange={handleChange}
                    >
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Self">Self</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel required>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active</MenuItem>
                      <MenuItem value="Pause">Pause</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {showDates && (
                  <>
                    <Grid item xs={11} sm={5} md={4} lg={2}>
                      <TextField
                        fullWidth
                        label="Pause Start Date"
                        name="PAUSE_START_DATE"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={2}>
                      <TextField
                        fullWidth
                        label="Pause End Date"
                        name="PAUSE_END_DATE"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={11} sm={5} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="Comments"
                    name="COMMENTS"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                {/* ////////////////////////////////////////////////////////////////// */}
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Class Information
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="package-type"
                    options={packageType}
                    onChange={(event, newValue) => {
                      handleChangePackageType(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.PACKAGE_TYPE}
                        label="Package Type"
                        required
                      />
                    )}
                    freeSolo
                  />
                </Grid>

                {formData.PACKAGE_TYPE === "BULK" ? (
                  <>
                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Bulk Hours Allocated"
                        name="BULK_HOURS_ALLOCATED"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.BULK_HOURS_ALLOCATED}
                        required
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Bulk Hours Used"
                        name="BULK_HOURS_USED"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.BULK_HOURS_USED}
                        required
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Bulk Price"
                        name="BULK_PRICE"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.BULK_PRICE}
                        required
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="time-slot"
                        options={timeSlot}
                        onChange={handleChangeTimeSlot}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.TIME_SLOT}
                            label="Time Slot"
                            required
                          />
                        )}
                        freeSolo
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="duration"
                        options={duration}
                        onChange={handleChangeDuration}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.DURATION}
                            label="Duration"
                            required
                          />
                        )}
                        freeSolo
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Price"
                        name="PRICE"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.PRICE}
                        required
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Court</InputLabel>
                        <Select
                          label="Court"
                          name="COURT"
                          onChange={handleChange}
                          value={formData.COURT}
                          required
                        >
                          <MenuItem value="1" disabled={!courts.court_1}>
                            Court 1
                          </MenuItem>
                          <MenuItem value="2" disabled={!courts.court_2}>
                            Court 2
                          </MenuItem>
                          <MenuItem value="3" disabled={!courts.court_3}>
                            Court 3
                          </MenuItem>
                          <MenuItem value="4" disabled={!courts.court_4}>
                            Court 4
                          </MenuItem>
                          <MenuItem value="5" disabled={!courts.court_5}>
                            Court 5
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Court</InputLabel>
                    <Select
                      label="COURT"
                      name="COURT"
                      onChange={handleChange}
                    >
                      <MenuItem value="1">Court 1</MenuItem>
                      <MenuItem value="2">Court 2</MenuItem>
                      <MenuItem value="3">Court 3</MenuItem>
                      <MenuItem value="4">Court 4</MenuItem>
                      <MenuItem value="5">Court 5</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Sport Type"
                    name="SPORT_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.SPORT_TYPE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="DISCOUNT"
                    name="DISCOUNT"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.DISCOUNT}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Total Amount"
                    name="TOTAL_AMOUNT"
                    variant="outlined"
                    value={formData.TOTAL_AMOUNT}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Renewed</InputLabel>
                    <Select
                      label="Renewed"
                      name="RENEWED"
                      onChange={handleChange}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="End Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.END_DATE} // Bind the END_DATE state variable
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "20% !important",
                      backgroundColor: " #306ef3!important",
                    }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default StudentData1;
