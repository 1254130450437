import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import SideBarEliteRole from "../Ellite_Role/SideBarEliteRole";
import { useLocation } from "react-router-dom";
// import Autocomplete from "@mui/material/Autocomplete/";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StudentEliteRole = () => {
  const location = useLocation();
  const selectedLogo = new URLSearchParams(location.search).get("selectedLogo");
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = useState([]);
  const [empCodes, setEmpCodes] = useState([]);
  const [packageIds, setPackageIds] = useState([]);
  const [package_Id, setpackage_Id] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  // Fetch data from Express.js backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/products`)
      .then((response) => {
        const { package_name, package_date, category_data } = response.data;

        const batchNames = package_date.map((entry) => entry.PACKAGE_BATCH);
        const packageName = package_name.map((entry) => entry.PACKAGE_NAME);
        const categoryName = category_data.map((entry) => entry.CATEGORY);

        setWeekDays(batchNames);
        setPackageName(packageName);
        setCategoryData(categoryName);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Fetch employee codes from Express.js backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user_details`)
      .then((response) => {
        setEmpCodes(response.data.map((entry) => entry.emp_code)); // Extracting emp_code from each entry
      })
      .catch((error) => {
        console.error("Error fetching employee codes:", error);
      });
  }, []); // Empty dependency array ensures the effect runs only once

  // useEffect(() => {
  //   // Fetch employee codes from Express.js backend
  //   axios
  //     .get("${API_BASE_URL}/Packages")
  //     .then((response) => {
  //       setpackage_Id(response.data.map((entry) => entry.package_id)); // Extracting emp_code from each entry
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching employee codes:", error);
  //     });
  // }, []); // Empty dependency array ensures the effect runs only once

  console.log(empCodes);

  const [formData, setFormData] = useState({
    EMP_CODE: "",
    NAME: "",
    SURNAME: "",
    SEX: "",
    AGE: "",
    PHONE_NUMBER: "",
    GUARDIAN_NAME: "",
    GUARDIAN_PHONE_NUMBER: "",
    RELATIONSHIP: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    STATUS: "",
    PACKAGE_NAME: "",
    SPORT_TYPE: "",
    DURATION: "",
    TIME_SLOT: "",
    START_DATE: "",
    END_DATE: "",
    PRICE: "",
    WEEK_DAYS: "",
    CATEGORY: "",
    PACKAGE_ID: "",
    RENEWED: "",
    DISCOUNT: "",
  });

  // to insert the data from frontend to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/student`, formData);
      console.log(response.data);
      window.alert("successfully inserted the student data");
    } catch (error) {
      console.error("Error inserting data:", error);
      window.alert("failed to insert the data");
    }
  };

  // to perform the discount operation and take the input from the user and store in the variables

  // Update handleChange function to handle changes for Employee Code (EMPCODE)
  const handleChange = (e) => {
    const { name, value } = e.target || e; // Account for both TextField and Autocomplete events
    // Update form data
    setFormData({
      ...formData,
      [name]: value, // Parse value to float
    });

    // Calculate total amount
    if (name === "START_DATE" || name === "DURATION") {
      const startDate =
        name === "START_DATE" ? new Date(value) : new Date(formData.START_DATE);
      const duration =
        name === "DURATION" ? parseInt(value) : parseInt(formData.DURATION);

      if (!isNaN(startDate.getTime()) && !isNaN(duration)) {
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + duration);
        setFormData((prevFormData) => ({
          ...prevFormData,
          END_DATE: endDate.toISOString().split("T")[0],
        }));
      }
    }
  };

  // start the auto fill of variables by selecting the package type which is from product table

  useEffect(() => {
    fetchOptions();
  }, []); // Fetch options on component mount

  const fetchOptions = () => {
    axios
      .get(`${API_BASE_URL}/api/options`)
      .then((response) => {
        console.log("Response:", response.data); // Log the response data
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error); // Log any errors
      });
  };

  const handleChangee = (newValue) => {
    let packageId = newValue; // Default to newValue as the package ID

    // Check if newValue is an object and extract package ID if needed
    if (typeof newValue === "object" && newValue !== null) {
      packageId = newValue.id; // Assuming package ID is stored under 'id' key
    }

    // Update packageId in the form data
    setFormData((prevData) => ({
      ...prevData,
      PACKAGE_ID: packageId,
    }));

    // Only send a request to the backend if packageId is not empty
    if (packageId.trim() !== "") {
      axios
        .post(`${API_BASE_URL}/packages`, {
          PACKAGE_ID: packageId,
        })
        .then((response) => {
          console.log(response.data);
          // Set the fetched package details in the form data
          const {
            PACKAGE_ID,
            PACKAGE_NAME,
            SPORT_TYPE,
            DURATION,
            PRICE,
            WEEK_DAYS,
            CATEGORY,
          } = response.data;

          setFormData((prevData) => ({
            ...prevData,
            PACKAGE_ID: PACKAGE_ID,
            CATEGORY: CATEGORY,
            WEEK_DAYS: WEEK_DAYS,
            PACKAGE_NAME: PACKAGE_NAME,
            SPORT_TYPE: SPORT_TYPE,
            DURATION: DURATION,
            PRICE: PRICE,
          }));
          console.log(formData);
        })
        .catch((error) => {
          console.error("Error fetching package data:", error);
          // Handle errors if needed
        });
    }
  };

  const handleChangeEmpCode = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      EMP_CODE: newValue,
    }));

    // Send data to server immediately
    sendDataToServer();
  };

  // Modify handleChangePackage to update PACKAGE_NAME
  // Modify handleChangePackage to update PACKAGE_NAME and send data to server immediately
  const handleChangePackage = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      PACKAGE_NAME: newValue,
    }));

    // Send data to server immediately
    sendDataToServer();
  };

  // Modify handleChangeCategory to update CATEGORY
  const handleChangeCategory = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      CATEGORY: newValue,
    }));
    sendDataToServer();
  };

  // Modify handleChangeWeekDay to update DAYS
  const handleChangeWeekDay = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      WEEK_DAYS: newValue,
    }));
    sendDataToServer();
  };

  // Function to send form data to the server
  const sendDataToServer = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/price`, formData);
      console.log(response.data);
      const { price, PACKAGE_ID } = response.data;

      // Update formData with the received price and id
      setFormData((prevFormData) => ({
        ...prevFormData,
        PRICE: price,
        PACKAGE_ID: PACKAGE_ID,
      }));
    } catch (error) {
      console.error("Error inserting data:", error);
      // window.alert("Failed to insert the data");
    }
  };

  const TIME_SLOT = [
    "06:00 AM - 07:00 AM",
    "07:00 AM - 08:00 AM",
    "08:00 AM - 09:00 AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 01:00 PM",
    "01:00 PM - 02:00 PM",
    "03:00 PM - 04:00 PM",
    "04:00 PM - 05:00 PM",
    "05:00 PM - 06:00 PM",
    "06:00 PM - 07:00 PM",
  ];

  // Call this function when any of the Autocomplete components are changed

  // Effect to trigger form submission when all fields are selected
  useEffect(() => {
    if (formData.PACKAGE_NAME && formData.WEEK_DAYS && formData.CATEGORY) {
      sendDataToServer();
    }
  }, [formData]);
  console.log(formData);

  // end of the fetching the data from the product table and display it.

  const title = "Student Details";

  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBarEliteRole selectedLogo={selectedLogo} />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={empCodes}
                    name="EMP_CODE" // Ensure name attribute matches the key in the formData state
                    onChange={(event, newValue) => {
                      handleChangeEmpCode(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Employee Code" />
                    )}
                  />
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="NAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="SURNAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Sex</InputLabel>
                    <Select label="Sex" name="SEX" onChange={handleChange}>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Age"
                    name="AGE"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian Name"
                    name="GUARDIAN_NAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian PhNo."
                    name="GUARDIAN_PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Relation</InputLabel>
                    <Select
                      label="Relation"
                      name="RELATIONSHIP"
                      onChange={handleChange}
                    >
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      onChange={handleChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active </MenuItem>
                      <MenuItem value="Pause">Pause</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* ////////////////////////////////////////////////////////////////// */}
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Class Information
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={weekDays}
                    name="WEEK_DAYS"
                    value={formData.WEEK_DAYS}
                    onChange={handleChangeWeekDay}
                    renderInput={(params) => (
                      <TextField {...params} label="Week Days" />
                    )}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="category"
                    name="CATEGORY"
                    options={categoryData}
                    value={formData.CATEGORY}
                    onChange={(event, newValue) => {
                      handleChangeCategory(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.CATEGORY}
                        label="Category"
                      />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="package-name"
                    options={packageName}
                    onChange={(event, newValue) => {
                      handleChangePackage(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.PACKAGE_NAME}
                        label="Package Name"
                      />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Sport Type"
                    name="SPORT_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.SPORT_TYPE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="PRICE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.PRICE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Renewed</InputLabel>
                    <Select
                      label="Renewed"
                      name="RENEWED"
                      onChange={handleChange}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Time Slot</InputLabel>
                    <Select
                      label="Time Slot"
                      fullWidth
                      value={formData.TIME_SLOT}
                      onChange={handleChange}
                      name="TIME_SLOT"
                      variant="outlined"
                    >
                      {TIME_SLOT.map((slot, index) => (
                        <MenuItem key={index} value={slot}>
                          {slot}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Duration"
                    name="DURATION"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Other</InputLabel>
                    <Select
                      label="Other"
                      name="DISCOUNT"
                      onChange={handleChange}
                    >
                      <MenuItem value="DISCOUNT">DISCOUNT</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="End Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.END_DATE} // Bind the END_DATE state variable
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "20% !important",
                      backgroundColor: " #306ef3!important",
                    }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default StudentEliteRole;
