import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import axios from "axios";

import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import "../ComponentsCSS/ManageButton.css";
import PopUpForm from "../SecondPage/PopUpForm";
import SideBar1 from "../Components/SideBar1";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Attendance1() {
  const [attendanceData, setAttendanceData] = useState([]);
  console.log(attendanceData);
  const [showPopup, setShowPopup] = useState(false);

  const [searchQuery, setSearchQuery] = useState(""); // State for employee code search query
  const [dateQuery, setDateQuery] = useState(""); // State for date search query

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetchskyAttendance`)
      .then((res) => {
        console.log(res.data);
        setAttendanceData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching attendance data:", error);
      });
  }, []);

  // Function to format date and time separately
  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toISOString().split("T")[0]; // Extract date part

    // Extract time part and split into hours, minutes, and seconds
    const timeString = dateTime.toISOString().split("T")[1].split(".")[0];
    const [hours, minutes, seconds] = timeString.split(":");

    // Convert hours to 12-hour format and determine AM/PM
    let formattedHours = parseInt(hours, 10);
    let period = "AM";
    if (formattedHours >= 12) {
      formattedHours -= 12;
      period = "PM";
    }
    if (formattedHours === 0) {
      formattedHours = 12; // 12 AM
    }

    // Format time as HH:MM AM/PM
    const time = `${formattedHours}:${minutes}:${seconds} ${period}`;

    return { date, time };
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px",
    },
  }));

  const StyledTableCellManual = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",

      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // Handle search input changes for employee code
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle search input changes for date
  const handleDateChange = (event) => {
    setDateQuery(event.target.value);
  };

  return (
    <div className="background">
      <Header title="Attendance Table" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar1 />

        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  <TextField
                    style={{ width: "224px" }}
                    label="Search by Employee Code"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <TextField
                    style={{ width: "224px" }}
                    label="Search by Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={dateQuery}
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    variant="contained"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      height: "35px",
                      marginTop: "4px",
                      backgroundColor: "#306ef3",
                      color: "white",
                    }}
                    onClick={togglePopup}
                  >
                    Manual attendance
                  </Button>
                </Stack>
              </Stack>
            </div>
            {showPopup && <PopUpForm onClose={togglePopup} />}
            <br />
            <br />
            <br />
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>emp code</StyledTableCell>
                    <StyledTableCell>emp name</StyledTableCell>
                    <StyledTableCell>Time Slot</StyledTableCell>
                    <StyledTableCell>date</StyledTableCell>
                    <StyledTableCell>check-in</StyledTableCell>
                    <StyledTableCell>check-out</StyledTableCell>
                    <StyledTableCell>Remarks</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceData &&
                    attendanceData.map((entry, index) => (
                      <StyledTableRow key={entry.emp_code + entry.date}>
                        <StyledTableCellManual>
                          {index + 1}
                        </StyledTableCellManual>
                        <StyledTableCellManual>
                          {entry.EMP_CODE}
                        </StyledTableCellManual>
                        <StyledTableCellManual>
                          {entry.NAME}
                        </StyledTableCellManual>
                        <StyledTableCellManual>
                          {entry.TIME_SLOT}
                        </StyledTableCellManual>
                        <StyledTableCellManual>
                          {entry.DATE.split("T")[0]}
                        </StyledTableCellManual>
                        <StyledTableCellManual>
                          {entry.CHECK_IN}
                        </StyledTableCellManual>
                        <StyledTableCellManual>
                          {entry.CHECK_OUT}
                        </StyledTableCellManual>
                        <StyledTableCellManual>
                          {entry.REMARKS}
                        </StyledTableCellManual>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            style={{ backgroundColor: "#306ef3" }}
          >
            Scroll To Top
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default Attendance1;
