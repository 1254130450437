import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from "recharts";
import { FaUser, FaUsers, FaFile } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import "../ComponentsCSS/AdminDashboard.css";
import { BsClipboard2DataFill } from "react-icons/bs";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function DashBoard({ selectedLogo }) {
  const [totalStudents, setTotalStudents] = useState(0);
  const [activeStudents, setActiveStudents] = useState(0);
  const [liveStudents, setLiveStudents] = useState(0);
  const [monthlyEnrollments, setMonthlyEnrollments] = useState(0);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [gaugeValue, setGaugeValue] = useState(0);

  const TIME_SLOT = [
    "06 - 07 AM",
    "07 - 08 AM",
    "08 - 09 AM",
    "09 - 10 AM",
    "10 - 11 AM",
    "11 - 12 PM",
    "12 - 01 PM",
    "01 - 02 PM",
    "02 - 03 PM",
    "03 - 04 PM",
    "04 - 05 PM",
    "05 - 06 PM",
    "06 - 07 PM",
    "07 - 08 PM",
    "08 - 09 PM",
    "09 - 10 PM",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/get_attendance`);
        if (Array.isArray(response.data)) {
          setAttendanceData(response.data);
        } else {
          console.error("Fetched data is not an array:", response.data);
          setAttendanceData([]); // Ensure it's set to an empty array if data is not an array
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setAttendanceData([]); // Set a default value in case of error
      }
    };

    fetchData();
  }, []);

  const handleTimeSlotChange = async (event) => {
    const selectedSlot = event.target.value;
    setSelectedTimeSlot(selectedSlot);

    try {
      const response = await axios.post(`${API_BASE_URL}/time_slot`, {
        selectedSlot,
      });
      const { count } = response.data;
      setGaugeValue(count);
    } catch (error) {
      console.error("Error fetching gauge value:", error);
    }
  };

  const chartData = attendanceData.map(({ TIME_SLOT, Attendance }) => ({
    timeSlot: TIME_SLOT,
    attendance: Attendance,
  }));

  function getCurrentMonthYear() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Adjust month to be zero-indexed

    return `${year}-${month}`;
  }
  const selectedMonth = getCurrentMonthYear();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/dashboard/getStudentCounts`
        );
        const {
          total_students,
          active_students,
          // null_check_outs,
          current_month_count,
        } = response.data;
        setTotalStudents(total_students);
        setActiveStudents(active_students);
        // setLiveStudents(null_check_outs);
        setMonthlyEnrollments(current_month_count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/dashboard/check-out-null`
        );
        const liveStudents = response.data.studentCount;

        setLiveStudents(liveStudents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <main className="main-container">
      <Header title="Dashboard" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar selectedLogo={selectedLogo} />

        <motion.div
          className="main-cards"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="row-cards">
            {/* // Total Student */}
            <Link to="/ManageMembers">
              <motion.div
                className="card"
                style={{ color: "#524e4ef2", marginRight: "0px" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1, duration: 0.5 }}
              >
                <div className="card-inner">
                  <h3>Student Data</h3>
                  <BsClipboard2DataFill className="card_icon" />
                </div>
                <h1>{totalStudents}</h1>
              </motion.div>
            </Link>

            {/* // Active Student Data */}
            <Link to="/ManageMembers/active">
              <motion.div
                className="card"
                style={{ color: "#524e4ef2", marginRight: "0px" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <div className="card-inner">
                  <h3> Active Student</h3>
                  <FaUser className="card_icon" />
                </div>
                <h1>{activeStudents}</h1>
              </motion.div>
            </Link>

            {/* // monthly enrollments */}
            <Link to={`/ManageMembers/${selectedMonth}`}>
              <motion.div
                className="card"
                style={{ color: "#524e4ef2", marginRight: "0px" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
              >
                <div className="card-inner">
                  <h3>Monthly Enrollments</h3>
                  <FaFile className="card_icon" />
                </div>
                <h1>{monthlyEnrollments}</h1>
              </motion.div>
            </Link>

            <Link to="/Attendance">
              <motion.div
                className="card"
                style={{ color: "#524e4ef2", marginRight: "0px" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              >
                <div className="card-inner">
                  <h3>Live Students</h3>
                  <FaUsers className="card_icon" />
                </div>
                <h1>
                  <p>{liveStudents}</p>
                </h1>
              </motion.div>
            </Link>

            {/* // circle chart */}
            <motion.div
              className="dropdown-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.6 }}
            >
              <select
                className="dropdown"
                value={selectedTimeSlot}
                onChange={handleTimeSlotChange}
              >
                {TIME_SLOT.map((slot) => (
                  <option key={slot} value={slot}>
                    {slot}
                  </option>
                ))}
              </select>
              <div className="gauge-container">
                <div
                  className="gauge"
                  style={{ "--value": `${gaugeValue * 3.6}deg` }}
                  data-value={gaugeValue}
                ></div>
              </div>
            </motion.div>

            {/* // bar chart */}
            <Link to="/Attendance">
              <motion.div
                className="card dashboard_graph"
                style={{ color: "#524e4ef2" }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.6 }}
              >
                <div className="card-inner">
                  <h3>Time Slot arrangement</h3>
                </div>
                {Array.isArray(chartData) && chartData.length > 0 ? (
                  <BarChart
                    width={600}
                    height={300}
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timeSlot" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="attendance" fill="#8884d8" />
                  </BarChart>
                ) : (
                  <p>No attendance data available</p>
                )}
              </motion.div>
            </Link>
          </div>
        </motion.div>
      </div>
    </main>
  );
}

export default DashBoard;
