import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  colors,
} from "@mui/material";
import axios from "axios";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header from "../Components/Header";
import SideBarEliteRole from "../Ellite_Role/SideBarEliteRole";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useLocation, useParams, Link } from "react-router-dom";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import TextField from "@mui/material/TextField";
import "../ComponentsCSS/ManageButton.css";
import { MdDelete } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ManageEliteRole() {
  const { ID } = useParams(); // Assuming you're using react-router for routing and have the ID in the
  const [userData, setUserData] = useState([]);
  const elementRef = useRef(null);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [arrowDisable, setArrowDisable] = useState(true);

  const location = useLocation();
  const selectedLogo = new URLSearchParams(location.search).get("selectedLogo");

  // Fetch operation to get data from the database
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetchstudent`)
      .then((res) => {
        setUserData(res.data);
        console.log(res.data);
        setFilteredUserData(res.data); // Filtered data with all sales data
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Filter sales data based on search query
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredUserData(userData); // Reset to show all data if search query is empty
    } else {
      const filteredData = userData.filter((row) =>
        row.dsr_id.toString().includes(searchQuery.trim())
      );
      setFilteredUserData(filteredData);
    }
  }, [searchQuery, userData]);

  // Horizontal scroll functionality
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  // Styled table cells and rows
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px !important",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Scroll to top functionality
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-IN");
  };

  const title = "Student Table";

  const handleDownload = (rowData) => {
    axios
      .post(`${API_BASE_URL}/pdf`, { rowData }, { responseType: "blob" }) // Set responseType to "blob" to receive binary data
      .then((res) => {
        console.log("Invoice download request successful");
        const blob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "details.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        console.log("PDF downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading invoice:", error);
      });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/student/${id}`);
      console.log(response.data.message);
      setUserData(userData.filter((student) => student.ID !== id));
      setFilteredUserData(
        filteredUserData.filter((student) => student.ID !== id)
      );
    } catch (err) {
      console.error("Error deleting student:", err);
      alert("An error occurred while deleting the student");
    }
  };

  const tableRef = useRef(null);

  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBarEliteRole />

        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  <TextField
                    style={{ width: "224px" }}
                    label="Search by Student Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />

                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      textDecoration: "none",
                      fontWeight: "bold",
                      height: "35px",
                      marginTop: "4px",
                      backgroundColor: "#306ef3",
                      color: "white",
                    }}
                    component={Link}
                    to="/StudentData"
                  >
                    {" "}
                    Add{" "}
                  </Button>
                </Stack>
              </Stack>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                sx={{ margin: "10px 10px 10px 10px" }}
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, -10);
                }}
                disabled={arrowDisable}
              >
                <FaArrowLeft />
              </Button>

              <Button
                sx={{ margin: "10px 10px 10px 0px" }}
                variant="outlined"
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, 10);
                }}
              >
                <FaArrowRight />
              </Button>
            </div>

            <TableContainer component={Paper} ref={elementRef}>
              <Table ref={tableRef} aria-label="customized table">
                <TableHead
                  sx={{
                    ".MuiTableCell-root": { fontWeight: "600" },
                  }}
                >
                  <TableRow>
                    <StyledTableCell>Emp Code</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Sex</StyledTableCell>
                    <StyledTableCell>Age</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    <StyledTableCell>Guardian Name</StyledTableCell>
                    <StyledTableCell>Guardian Phone Number</StyledTableCell>
                    <StyledTableCell>Relationship</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>City</StyledTableCell>
                    <StyledTableCell>Sport Type</StyledTableCell>
                    <StyledTableCell>Package Name</StyledTableCell>
                    <StyledTableCell>Week Days</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Start Date</StyledTableCell>
                    <StyledTableCell>End Date</StyledTableCell>
                    <StyledTableCell>Time Slot</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(filteredUserData) &&
                    filteredUserData.map((row) => (
                      <StyledTableRow key={row.ID}>
                        <StyledTableCell>{row.emp_code}</StyledTableCell>
                        <StyledTableCell>{row.NAME}</StyledTableCell>
                        <StyledTableCell>{row.SEX}</StyledTableCell>
                        <StyledTableCell>{row.AGE}</StyledTableCell>
                        <StyledTableCell>{row.PHONE_NO}</StyledTableCell>
                        <StyledTableCell>{row.GUARDIAN_NAME}</StyledTableCell>
                        <StyledTableCell>
                          {row.GUARDIAN_PHONE_NO}
                        </StyledTableCell>
                        <StyledTableCell>{row.RELATIONSHIP}</StyledTableCell>
                        <StyledTableCell>{row.ADDRESS}</StyledTableCell>
                        <StyledTableCell>{row.CITY}</StyledTableCell>
                        <StyledTableCell>{row.SPORTS_TYPE}</StyledTableCell>
                        <StyledTableCell>{row.PACKAGE_NAME}</StyledTableCell>
                        <StyledTableCell>{row.WEEK_DAYS}</StyledTableCell>
                        <StyledTableCell>{row.PRICE}</StyledTableCell>
                        <StyledTableCell>{row.CATEGORY}</StyledTableCell>
                        <StyledTableCell>
                          {formatDate(row.START_DATE)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatDate(row.END_DATE)}
                        </StyledTableCell>
                        <StyledTableCell>{row.TIME_SLOT}</StyledTableCell>
                        <StyledTableCell>
                          <Stack direction="row" spacing={1}>
                            <Button
                              className="ManageButton"
                              variant="contained"
                              color="secondary"
                              size="small"
                            >
                              <Link to={`/UpdateStudentRole/${row.ID}`}>
                                {" "}
                                <EditCalendarIcon
                                  style={{ height: "15px", color: "white" }}
                                />
                              </Link>
                            </Button>

                            <Button
                              className="ManageButton"
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleDownload(row)}
                            >
                              <IoMdDownload
                                style={{ height: "20px", color: "white" }}
                              />
                            </Button>
                            {/* <Button
                              className="ManageButton"
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={() => handleDelete(row.ID)} // Adding handleDelete function call
                            > 
                              <MdDelete
                                style={{ height: "20px", color: "white" }}
                              />
                            </Button> */}
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{
                  backgroundColor: "#205295",
                  color: "white",
                  margin: "30px 30px 30px 30px",
                }}
                onClick={handleScrollToTop}
              >
                Back to Top
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ManageEliteRole;
