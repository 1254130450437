import React, { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const NotificationsList = ({ anchorElN, handleCloseN, notifications }) => {
  const open = Boolean(anchorElN);

  // Effect to listen for clicks on the document body
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorElN && !anchorElN.contains(event.target)) {
        handleCloseN();
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [anchorElN, handleCloseN]);

  const handleNotificationClick = () => {
    handleCloseN();
  };

  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  // Filter notifications for today and get the latest two
  const filteredNotifications = notifications
    .filter((notification) => notification.date === today)
    .slice(0, 2); // Get only the first two notifications

  return (
    <Paper
      sx={{
        position: "absolute",
        top: anchorElN ? anchorElN.getBoundingClientRect().bottom : 0,
        right: 0,
        width: "300px",
        maxHeight: "400px",
        overflowY: "auto",
        display: open ? "block" : "none",
        zIndex: 1300,
      }}
      elevation={3}
    >
      <List>
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification, index) => {
            // Format punch_time to display time
            const checkInTime = new Date(
              notification.punch_time
            ).toLocaleTimeString("en-US", { hour12: false });

            return (
              <React.Fragment key={index}>
                <ListItem button onClick={handleNotificationClick}>
                  <Typography variant="body1">
                    {notification.student_name} Check-in at {checkInTime} on{" "}
                    {notification.date} instead of the time slot{" "}
                    {notification.timeslot}.
                  </Typography>
                </ListItem>
                {index < filteredNotifications.length - 1 && <Divider />}
              </React.Fragment>
            );
          })
        ) : (
          <ListItem>
            <Typography variant="body1">
              No notifications found today.
            </Typography>
          </ListItem>
        )}
      </List>
    </Paper>
  );
};

export default NotificationsList;
