import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import "../ComponentsCSS/ManageButton.css";
import PopUpForm from "../Components/PopUpForm";
import * as XLSX from "xlsx";
import HeaderAttendance from "../Components/HeaderAttendance";
import { MdDelete } from "react-icons/md";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TIME_SLOTS = [
  "06 - 07 AM",
  "07 - 08 AM",
  "08 - 09 AM",
  "09 - 10 AM",
  "10 - 11 AM",
  "11 - 12 AM",
  "12 - 01 PM",
  "01 - 02 PM",
  "02 - 03 PM",
  "03 - 04 PM",
  "04 - 05 PM",
  "05 - 06 PM",
  "06 - 07 PM",
  "07 - 08 PM",
  "08 - 09 PM",
  "09 - 10 PM",
];

const TIME_SLOT_END_TIMES = {
  "06 - 07 AM": "7:00:00 AM",
  "07 - 08 AM": "8:00:00 AM",
  "08 - 09 AM": "9:00:00 AM",
  "09 - 10 AM": "10:00:00 AM",
  "10 - 11 AM": "11:00:00 AM",
  "11 - 12 AM": "12:00:00 AM",
  "12 - 01 PM": "1:00:00 PM",
  "01 - 02 PM": "2:00:00 PM",
  "02 - 03 PM": "3:00:00 PM",
  "03 - 04 PM": "4:00:00 PM",
  "04 - 05 PM": "5:00:00 PM",
  "05 - 06 PM": "6:00:00 PM",
  "06 - 07 PM": "7:00:00 PM",
  "07 - 08 PM": "8:00:00 PM",
  "08 - 09 PM": "9:00:00 PM",
  "09 - 10 PM": "10:00:00 PM",
};

function Attendance({ setAttendanceCount, setinvalidCheckIns }) {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(inOutTimes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance Data");
    XLSX.writeFile(workbook, "attendance_data.xlsx");
  };

  const [attendanceData, setAttendanceData] = useState({
    iclockData: [],
    eliteData: [],
  });
  const [alerts, setAlerts] = useState([]); // State for alerts
  const [showPopup, setShowPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for employee code search query
  const [dateQuery, setDateQuery] = useState({ startDate: "", endDate: "" }); // State for date search query
  const [timeSlotQuery, setTimeSlotQuery] = useState(""); // State for time slot query
  const [inOutTimes, setInOutTimes] = useState([]);
  const [invalidCheckIns, setInvalidCheckIns] = useState([]);

  const [customDateQuery, setCustomDateQuery] = useState(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    return currentDate;
  });

  const [red, setRed] = useState(false); // State variable to control text color
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // State to store alerted students
  const [alertedStudents, setAlertedStudents] = useState([]);

  const fetchData = () => {
    axios
      .get(`${API_BASE_URL}/fetchAttendance`)
      .then((res) => {
        setAttendanceData(res.data.combinedData);
      })
      .catch((error) => {
        console.error("Error fetching attendance data:", error);
      });
  };

  useEffect(() => {
    // Fetch data immediately on mount
    fetchData();

    // Set up polling
    const intervalId = setInterval(fetchData, POLLING_INTERVAL);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const combinedAttendanceData = [...attendanceData.iclockData];

    console.log(attendanceData.eliteData);
    const combinedData = preprocessAttendanceData(combinedAttendanceData);
    const processedInOutTimes = getInOutTimes(combinedData);
    setInOutTimes(processedInOutTimes);

    const invalidCheckInss = [];
    for (const entry of processedInOutTimes) {
      if (!checkTimeSlot(entry.checkIn, entry.time_slot, entry.date)) {
        invalidCheckInss.push({
          emp_code: entry.emp_code,
          student_name: entry.student_name,
          date: entry.date,
          punch_time: entry.checkInDate,
          timeslot: entry.time_slot,
        });
      }
    }
    setInvalidCheckIns(invalidCheckInss);

    // Sending data to the server
    const sendInvalidCheckIns = async () => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/invalid-checkins`,
          invalidCheckInss,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          const newEntries = response.data.newEntries;
          const currentDate = new Date().toISOString().split("T")[0];
          const entriesWithCurrentDate = newEntries.filter(
            (entry) => entry.date === currentDate
          );
          if (entriesWithCurrentDate.length > 0) {
            const empCodes = entriesWithCurrentDate
              .map((entry) => `(${entry.emp_code} - ${entry.student_name})`)
              .join(", ");

            alert(
              `New invalid check-ins detected today! Student ${empCodes} is trying to enter in the wrong time slot.`
            );
          }
        } else if (response.status !== 200) {
          throw new Error("Failed to send data to the server");
        }
      } catch (error) {
        console.error("Error sending data to the server:", error);
      }
    };

    sendInvalidCheckIns();
  }, [attendanceData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkInvalidCheckIns();
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [inOutTimes]);

  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toISOString().split("T")[0]; // Extract date part

    // Extract time part and split into hours, minutes, and seconds
    const timeString = dateTime.toISOString().split("T")[1].split(".")[0];
    const [hours, minutes, seconds] = timeString.split(":");

    // Convert hours to 12-hour format and determine AM/PM
    let formattedHours = parseInt(hours, 10);
    let period = "AM";
    if (formattedHours >= 12) {
      formattedHours -= 12;
      period = "PM";
    }
    if (formattedHours === 0) {
      formattedHours = 12; // 12 AM
    }

    // Format time as HH:MM AM/PM
    const time = `${formattedHours}:${minutes}:${seconds} ${period}`;

    return { date, time };
  };

  const preprocessAttendanceData = (data) => {
    console.log(data);
    const combinedData = { data };
    // data.forEach((entry) => {
    //   if (entry.punch_time) {
    //     const key = `${entry.emp_code}_${entry.punch_time.split("T")[0]}`;
    //     if (!combinedData[key]) {
    //       combinedData[key] = [];
    //     }
    //     combinedData[key].push(entry);
    //   }
    // });
    return combinedData;
  };

  const getInOutTimes = (combinedData) => {
    const inOutTimes = [];
    for (const key in combinedData) {
      const entries = combinedData[key];
      entries.forEach((entry) => {
        inOutTimes.push({
          id: entry.id,
          emp_code: entry.emp_code,
          date: entry.punch_time.split("T")[0],
          checkIn: formatDate(entry.punch_time).time,
          checkInDate: entry.punch_time,
          checkOut: TIME_SLOT_END_TIMES[entry.timeslot] || "N/A",
          student_name: entry.student_name,
          time_slot: entry.timeslot,
        });
      });
    }

    return inOutTimes;
  };

  // Function to get current time in the format HH:MM AM/PM
  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const formattedHours = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";
    return `${formattedHours}:${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    } ${period}`;
  };

  const checkTimeSlot = (checkInTime, timeSlot) => {
    // Extract hours, minutes, and period (AM/PM) from checkInTime
    const [checkInHourStr, checkInMinuteStr, period] = checkInTime.split(/:| /);
    const checkInHour = parseInt(checkInHourStr, 10);
    const checkInMinute = parseInt(checkInMinuteStr, 10);

    // Extract start and end hours from timeSlot
    const [startHourStr, startMinuteStr] = timeSlot.split(" - ")[0].split(":");
    const [endHourStr, endMinuteStr] = timeSlot
      .split(" - ")[1]
      .replace(" PM", "")
      .replace(" AM", "")
      .split(":");
    const startHour = parseInt(startHourStr, 10);
    const startMinute = parseInt(startMinuteStr || "0", 10); // Default to 0 if startMinute is not provided
    const endHour = parseInt(endHourStr, 10);
    const endMinute = parseInt(endMinuteStr || "0", 10); // Default to 0 if endMinute is not provided

    // Convert checkInHour to 24-hour format based on period
    let checkInHour24 = checkInHour;
    if (period === "PM" && checkInHour !== 12) {
      checkInHour24 += 12;
    } else if (period === "AM" && checkInHour === 12) {
      checkInHour24 = 0;
    }

    // Create Date objects for comparison
    const checkInDate = new Date();
    checkInDate.setHours(checkInHour24, checkInMinute, 0, 0);

    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHour, endMinute, 0, 0);

    // Subtract 15 minutes from start time for grace period
    const gracePeriodDate = new Date(startDate);
    gracePeriodDate.setMinutes(startDate.getMinutes() - 15);

    // Check if checkInDate is within the grace period and time slot
    return checkInDate >= gracePeriodDate && checkInDate < endDate;
  };

  const checkInvalidCheckIns = () => {
    const invalidCheckInss = inOutTimes.filter((entry) => {
      return !checkTimeSlot(entry.checkIn, entry.time_slot, entry.date);
    });

    const newAlerts = invalidCheckInss.filter(
      (entry) => !alertedStudents.includes(entry.emp_code)
    );

    if (newAlerts.length > 0) {
      setAlerts((prevAlerts) => [...prevAlerts, ...newAlerts]);
      setAlertedStudents((prevAlerted) => [
        ...prevAlerted,
        ...newAlerts.map((entry) => entry.emp_code),
      ]);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: "#388f91",
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px",
    },
  }));

  // below one add color to the table body
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  // end of adding color to the table

  const checkTimeSlotFilter = (checkInTime, timeSlot) => {
    console.log(checkInTime, timeSlot);

    // Extract hours, minutes, and period (AM/PM) from checkInTime
    const [checkInHourStr, checkInMinuteStr, checkInSecondStr, period] =
      checkInTime.split(/:| /);
    const checkInHour = parseInt(checkInHourStr, 10);
    const checkInMinute = parseInt(checkInMinuteStr, 10);

    // Extract start and end times from timeSlot
    const [startTime, endTime] = timeSlot.split(" - ");
    const [startHourStr, startMinuteStr = "0"] = startTime.split(":");
    const [endHourStr, endMinuteStr = "0"] = endTime.split(":");

    const startHour = parseInt(startHourStr, 10);
    const startMinute = parseInt(startMinuteStr, 10);
    const endHour = parseInt(endHourStr, 10);
    const endMinute = parseInt(endMinuteStr, 10);

    // Convert checkInHour to 24-hour format based on period
    let checkInHour24 = checkInHour;
    if (period === "PM" && checkInHour !== 12) {
      checkInHour24 += 12;
    } else if (period === "AM" && checkInHour === 12) {
      checkInHour24 = 0;
    }

    // Convert start and end hours to 24-hour format based on timeSlot (assuming PM)
    let startHour24 = startHour;
    let endHour24 = endHour;

    if (timeSlot.includes("PM") && startHour !== 12) {
      startHour24 += 12;
    }
    if (timeSlot.includes("PM") && endHour !== 12) {
      endHour24 += 12;
    }

    // Convert times to minutes since midnight for comparison
    const checkInTimeInMinutes = checkInHour24 * 60 + checkInMinute;
    const startTimeInMinutes = startHour24 * 60 + startMinute;
    const endTimeInMinutes = endHour24 * 60 + endMinute;

    // Check if checkInTimeInMinutes is within the time slot
    const isValidTime =
      checkInTimeInMinutes >= startTimeInMinutes &&
      checkInTimeInMinutes < endTimeInMinutes;
    console.log(
      `Check-in time: ${checkInTime}, Start time: ${startHour24}:${startMinute}, End time: ${endHour24}:${endMinute}, Is valid: ${isValidTime}`
    );
    return isValidTime;
  };

  // Update the filteredInOutTimes and filteredEliteData based on timeSlotQuery
  const filteredInOutTimes = inOutTimes.filter((entry) => {
    const empCodeMatch = searchQuery
      ? entry.student_name?.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    const dateMatch =
      dateQuery.startDate && dateQuery.endDate
        ? new Date(entry.date) >= new Date(dateQuery.startDate) &&
          new Date(entry.date) <= new Date(dateQuery.endDate)
        : true;

    const timeSlotMatch = timeSlotQuery
      ? checkTimeSlotFilter(entry.checkIn, timeSlotQuery, entry.date)
      : true;

    const customDateMatch = customDateQuery
      ? entry.date === customDateQuery
      : true;

    return empCodeMatch && dateMatch && timeSlotMatch && customDateMatch;

    // return empCodeMatch && dateMatch && timeSlotMatch;
  });

  const filteredEliteData = attendanceData.eliteData.filter((entry) => {
    const empCodeMatch = searchQuery
      ? entry.NAME?.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    const dateMatch =
      dateQuery.startDate && dateQuery.endDate
        ? new Date(entry.DATE.split("T")[0]) >= new Date(dateQuery.startDate) &&
          new Date(entry.DATE.split("T")[0]) <= new Date(dateQuery.endDate)
        : true;

    const timeSlotMatch = timeSlotQuery
      ? checkTimeSlotFilter(
          entry.CHECK_IN,
          timeSlotQuery,
          entry.DATE.split("T")[0]
        )
      : true;

    const customDateMatch = customDateQuery
      ? entry.date === customDateQuery
      : true;

    return empCodeMatch && dateMatch && timeSlotMatch && customDateMatch;
    // return empCodeMatch && dateMatch && timeSlotMatch;
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDateQuery((prev) => ({ ...prev, [name]: value }));
  };

  const handleTimeSlotChange = (event) => {
    setTimeSlotQuery(event.target.value);
  };

  const countStudentsInCurrentTimeSlot = () => {
    const now = new Date(); // Current date and time

    // Format current time as HH:MM AM/PM
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentPeriod = currentHour >= 12 ? "PM" : "AM";
    const formattedCurrentTime = `${currentHour % 12 || 12}:${
      currentMinute < 10 ? "0" + currentMinute : currentMinute
    } ${currentPeriod}`;

    // Filter inOutTimes for today's date
    const today = new Date().toISOString().split("T")[0]; // Today's date in YYYY-MM-DD format
    const checkInsToday = inOutTimes.filter((entry) => entry.date === today);

    // Count students with check-ins in the current time slot
    let studentCount = 0;
    checkInsToday.forEach((entry) => {
      // Check if current time falls within the time slot of the entry
      if (checkTimeSlotFilter(entry.checkIn, entry.time_slot)) {
        studentCount++;
      }
    });
    console.log(studentCount);
    return studentCount;
  };

  const sendCountToBackend = async (count) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/dashboard/check-out-null`,
        {
          count,
        }
      );
      console.log(count);
      console.log("Count sent to backend successfully:", response.data);
    } catch (error) {
      console.error("Error sending count to backend:", error);
    }
  };

  // UseEffect to send count data whenever inOutTimes changes
  useEffect(() => {
    const count = countStudentsInCurrentTimeSlot();
    // Call function to send count to backend
    sendCountToBackend(count);
  }, [inOutTimes]); // Run whenever inOutTimes changes

  useEffect(() => {
    const intervalId = setInterval(() => {
      const count = countStudentsInCurrentTimeSlot();

      // Call function to send count to backend
      sendCountToBackend(count);
    }, 1000); // Check every second

    // Cleanup function to clear interval on unmount
    return () => clearInterval(intervalId);
  }, [inOutTimes]); // Empty dependency array ensures this effect runs only once

  const handleDelete = async (id, check_in, check_out, date) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/attendance-del/${id}`,
        {
          data: { check_in, check_out, date },
        }
      );

      if (response.status === 200) {
        // Update the state to remove the deleted entry from the table
        filteredInOutTimes(
          filteredInOutTimes.filter((entry) => entry.id !== id)
        );

        // Reload the window
        window.location.reload();
      } else {
        console.error("Failed to delete entry");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteelite = async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/attendance-del/elite/${id}`
      );

      if (response.status === 200) {
        // Update the state to remove the deleted entry from the table
        filteredEliteData(filteredEliteData.filter((entry) => entry.id !== id));

        // Reload the window
        window.location.reload();
      } else {
        console.error("Failed to delete entry");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  console.log(filteredInOutTimes);
  console.log(filteredEliteData);
  return (
    <div className="background">
      <HeaderAttendance
        title="Attendance Table"
        invalidCheckIns={invalidCheckIns}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <div className="static-table">
          <SideBar />
        </div>
        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main" className="mainn">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  <TextField
                    style={{ width: "224px" }}
                    label="Search Student"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />

                  <TextField
                    style={{ width: "224px" }}
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="startDate"
                    value={dateQuery.startDate}
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    style={{ width: "224px" }}
                    label="End Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="endDate"
                    value={dateQuery.endDate}
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <FormControl variant="outlined" style={{ width: "224px" }}>
                    <InputLabel>Time Slot</InputLabel>
                    <Select
                      value={timeSlotQuery}
                      onChange={handleTimeSlotChange}
                      label="Time Slot"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {TIME_SLOTS.map((slot) => (
                        <MenuItem key={slot} value={slot}>
                          {slot}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    style={{ width: "224px" }}
                    label="Search Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="customDate"
                    value={customDateQuery}
                    onChange={(e) => setCustomDateQuery(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Button
                    variant="contained"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      height: "45px",

                      // marginTop: "4px"
                      backgroundColor: "#306ef3",
                      color: "white",
                    }}
                    onClick={togglePopup}
                  >
                    Manual attendance
                  </Button>
                </Stack>
              </Stack>
            </div>
            {showPopup && <PopUpForm onClose={togglePopup} />}
            <br />
            <br />
            <br />
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>Student Id</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Time Slot</StyledTableCell>
                    <StyledTableCell>date</StyledTableCell>
                    <StyledTableCell>check-in</StyledTableCell>
                    <StyledTableCell>check-out</StyledTableCell>
                    <StyledTableCell>Remarks</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInOutTimes.map((entry, index) => {
                    console.log({ entry, index });
                    const isInvalid = invalidCheckIns.some((invalidRow) => {
                      if (invalidRow.date) {
                        return (
                          invalidRow.emp_code === entry.emp_code &&
                          invalidRow.date === entry.date &&
                          invalidRow.punch_time == entry.checkInDate
                        );
                      }
                      return invalidRow.emp_code === entry.emp_code;
                    });

                    return (
                      <StyledTableRow
                        key={entry.emp_code + entry.date}
                        sx={{
                          backgroundColor: isInvalid
                            ? "#f08080 !important"
                            : "inherit",
                        }}
                      >
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{entry.emp_code}</StyledTableCell>
                        <StyledTableCell>{entry.student_name}</StyledTableCell>
                        <StyledTableCell>{entry.time_slot}</StyledTableCell>
                        <StyledTableCell>{entry.date}</StyledTableCell>
                        <StyledTableCell>{entry.checkIn}</StyledTableCell>
                        <StyledTableCell>{entry.checkOut}</StyledTableCell>
                        <StyledTableCell>-</StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          <Button
                            className="ManageButton"
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() =>
                              handleDelete(
                                entry.id,
                                entry.checkIn,
                                entry.checkOut,
                                entry.date
                              )
                            } // Adding handleDelete function call
                          >
                            <MdDelete
                              style={{ height: "20px", color: "white" }}
                            />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
                <TableBody>
                  {filteredEliteData.map((entry, index) => (
                    <StyledTableRow key={entry.emp_code + entry.DATE}>
                      <StyledTableCell>
                        {filteredInOutTimes.length + index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{entry.emp_code}</StyledTableCell>
                      <StyledTableCell>{entry.NAME}</StyledTableCell>
                      <StyledTableCell>{entry.TIME_SLOT}</StyledTableCell>
                      <StyledTableCell>
                        {entry.DATE.split("T")[0]}
                      </StyledTableCell>
                      <StyledTableCell>{entry.CHECK_IN}</StyledTableCell>
                      <StyledTableCell>{entry.CHECK_OUT}</StyledTableCell>
                      <StyledTableCell>{entry.REMARKS}</StyledTableCell>
                      <StyledTableCell>
                        {" "}
                        <Button
                          className="ManageButton"
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => handleDeleteelite(entry.ID)} // Adding handleDelete function call
                        >
                          <MdDelete
                            style={{ height: "20px", color: "white" }}
                          />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              style={{ backgroundColor: "#306ef3" }}
            >
              Scroll To Top
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Attendance;
