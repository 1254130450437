import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { Link, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "../ComponentsCSS/ManageButton.css";
// import { MdDelete } from "react-icons/md";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ManagePackage() {
  const { ID } = useParams(); // Assuming you're using react-router for routing and have the ID in the

  const [userData, setUserData] = useState([]);
  const elementRef = useRef(null);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [arrowDisable, setArrowDisable] = useState(true);
  const [statusFilter, setStatusFilter] = useState(""); // State for status filter

  // start providing the fetch operation to take data from database
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetchPackage`)
      .then((res) => {
        setUserData(res.data);
        console.log(res.data);
        setFilteredUserData(res.data); // filtered data with all sales data
        // window.alert("fetching of data done");
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        // window.alert("unable to fetch the data");
        // Handle error (e.g., display a message to the user)
      });
  }, []);

  // Filter sales data based on search query
  useEffect(() => {
    let filteredData = userData;

    // if (searchQuery.trim() !== "") {
    //   filteredData = filteredData.filter((row) =>
    //     row.dsr_id.toString().includes(searchQuery.trim())
    //   );
    // }

    if (statusFilter !== "") {
      filteredData = filteredData.filter((row) => row.STATUS === statusFilter);
    }

    setFilteredUserData(filteredData);
  }, [searchQuery, statusFilter, userData]);

  // filter condition function for cathe
  // const handleSearchInputChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  // Start providing the color to the tables
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
      verticalAlign: "top",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px !important",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const title = "Package Table";

  return (
    <div className="background">
      <Header title={title} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar />

        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  {/* <TextField
                    style={{ width: "224px" }}
                    label="Search by Employee Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  /> */}

                  <FormControl variant="outlined" style={{ width: "224px" }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active</MenuItem>
                    </Select>
                  </FormControl>

                  <Button
                    variant="contained"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      height: "35px",
                      marginTop: "4px",
                      backgroundColor: "#306ef3",
                      color: "white",
                    }}
                    component={Link}
                    to="/Packages"
                  >
                    {" "}
                    Add{" "}
                  </Button>
                </Stack>
              </Stack>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                sx={{ margin: "10px 10px 10px 10px" }}
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, -10);
                }}
                disabled={arrowDisable}
              >
                <FaArrowLeft />
              </Button>
              <Button
                sx={{ margin: "10px 10px 10px 0px" }}
                variant="outlined"
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, 10);
                }}
              >
                <FaArrowRight />
              </Button>
            </div>

            <TableContainer component={Paper} ref={elementRef}>
              <Table>
                <TableHead
                  sx={{
                    ".MuiTableCell-root": { fontWeight: "600" },
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Week Days</StyledTableCell>
                    <StyledTableCell>Package Name</StyledTableCell>
                    <StyledTableCell>Sport Type</StyledTableCell>
                    <StyledTableCell>Duration</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(filteredUserData) &&
                    filteredUserData.map((row) => (
                      <StyledTableRow key={row.ID}>
                        <StyledTableCell>{row.PACKAGE_ID}</StyledTableCell>
                        <StyledTableCell>{row.CATEGORY}</StyledTableCell>
                        <StyledTableCell>{row.WEEK_DAYS}</StyledTableCell>
                        <StyledTableCell>{row.PACKAGE_NAME}</StyledTableCell>
                        <StyledTableCell>{row.SPORT_TYPE}</StyledTableCell>
                        <StyledTableCell>{row.DURATION}</StyledTableCell>
                        <StyledTableCell>{row.PRICE}</StyledTableCell>
                        <StyledTableCell>{row.STATUS}</StyledTableCell>
                        <StyledTableCell>
                          <Stack direction="row" spacing={1}>
                            <Button
                              className="ManageButton"
                              variant="contained"
                              color="secondary"
                              size="small"
                            >
                              <Link to={`/UpdatePackage/${row.PACKAGE_ID}`}>
                                <EditCalendarIcon
                                  style={{ height: "15px", color: "white" }}
                                />
                              </Link>
                            </Button>
                            {/* <Button
                            className="ManageButton"
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleDelete(row.PACKAGE_ID)} // Adding handleDelete function call
                          > 
                            <MdDelete
                              style={{ height: "20px", color: "white" }}
                            />
                          </Button> */}
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleScrollToTop}
            style={{ backgroundColor: "#306ef3", marginTop: "10px" }}
          >
            Scroll To Top
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default ManagePackage;
