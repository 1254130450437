import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import "./Courts.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Courts = () => {
  // Default state for courts
  const defaultCourtData = {
    COURT_1: ["", 0],
    COURT_2: ["", 0],
    COURT_3: ["", 0],
    COURT_4: ["", 0],
    COURT_5: ["", 0],
  };

  const [courtData, setCourtData] = useState(defaultCourtData);

  const TIME_SLOT = [
    "5 - 6 AM",
    "6 - 7 AM",
    "7 - 8 AM",
    "8 - 9 AM",
    "9 - 10 AM",
    "7 - 8 PM",
    "8 - 9 PM",
    "9 - 10 PM",
    "5:30 - 7:00 AM",
    "4:00 - 5:30 PM",
    "5:00 - 6:30 PM",
    "5:30 - 7:00 PM",
  ];

  const handleTimeSlotChange = async (event) => {
    const selectedSlot = event.target.value;

    try {
      const response = await axios.post(
        `${API_BASE_URL}/sky_courts/dashboard-count`,
        { TIME_SLOT: selectedSlot }
      );
      const data = response.data;

      // Merge received data with default state to ensure all courts are displayed
      const updatedCourtData = { ...defaultCourtData, ...data };
      setCourtData(updatedCourtData);
    } catch (error) {
      console.error("Error fetching court data:", error);
    }
  };

  return (
    <div>
      <div className="courts-dropdown">
        <select className="dropdown" onChange={handleTimeSlotChange}>
          <option value="">TimeSlots</option>
          {TIME_SLOT.map((slot) => (
            <option className="courts-dropdown-option" key={slot} value={slot}>
              {slot}
            </option>
          ))}
        </select>
        <Button
          variant="contained"
          style={{
            color: "white",
            textDecoration: "none",
            fontWeight: "bold",
            height: "35px",
            margin:"20px",
            backgroundColor: "#306ef3",
            color: "white",
          }}
          component={Link}
          to="/StudentData1"
        >
          Add{" "}
        </Button>
      </div>

      <div id="block1">
        <h3 style={{ color: "black" }}>Courts Data</h3>
        {Object.keys(courtData).map((court) => {
          const [packageName, count] = courtData[court];

          return (
            <div key={court} className="court1">
              <div>{court.replace("_", " ")}</div>
              <div>
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    color: count === 6 ? "red" : "black",
                    backgroundColor: "white",
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  {count || 0}
                </Box>
              </div>
              <div>{packageName || ""}</div>
            </div>
          );
        })}
        <h3 style={{ color: "white" }}>Courts Data</h3>
      </div>
    </div>
  );
};

export default Courts;
