import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
} from "react-pro-sidebar";
import {
  FaAlignJustify,
  FaUsers,
  FaUserTie,
  FaIndustry,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { FaChartSimple } from "react-icons/fa6";
import { BsDatabaseFillAdd } from "react-icons/bs";
import { AiOutlineAreaChart, AiOutlineAudit } from "react-icons/ai";

import { MdAccountCircle } from "react-icons/md";
import "../ComponentsCSS/Sidebar.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import elite from "../Components/elite1.png";
import skyline from "../Components/skyline.png";



const SideBar = ({selectedLogo }) => {

  console.log(selectedLogo + " out put getting");

  const [width, setWidth] = useState(window.innerWidth);
  const [collapse, setCollapse] = useState(false);
  const [display, setDisplay] = useState("");
  const [breakPoint, setBreakPoint] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserData(userData);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setWidth(windowWidth);
      console.log(windowWidth);
      if (windowWidth < 600) {
        setCollapse(true);
        setDisplay("");
        setBreakPoint(true);
      } else {
        setCollapse(false);
        setDisplay("display");
        setBreakPoint(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCollapse = () => {
    setCollapse(!collapse);
    setBreakPoint(!collapse);
  };




    // Map selectedSport to the respective image file
    const getImageSource = () => {
      if (selectedLogo === "swimming") {
        return elite; // Replace with the actual path to the elite logo image
      } else if (selectedLogo === "badminton") {
        return skyline; // Replace with the actual path to the skyline logo image
      } else {
        return "default_image_path"; // If no selectedLogo matches, provide a default image path
      }
    };

  return (
    <div className="sidebar">

      <Sidebar
        collapsed={collapse}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "#1c3976",
            height: "100vh",
            paddingTop: "40px",
            position: "relative",
            bottom: "65px",
          },
        }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, disabled }) => ({
              color: disabled ? "white" : "white",
              "&:hover": {
                backgroundColor: "#8a8dc5",
              },
            }),
          }}
        >

       {!collapse && (
            <>
              <Box
                component="img"
                className="logo"
                alt="Your logo."
                src={elite}
              />
       
            </>
          )}

          {/* <img src={getImageSource()} alt="Logo" /> */}

          <h3 className="Admin">{userData.username}</h3>

            {/* ............ Start of the sidebar fields........................ */}
          <Link to="/DashBoardEliteRole">
            <MenuItem icon={<FaChartSimple />}> DashBoard </MenuItem>
          </Link>





          <SubMenu icon={<FaUsers />} className="Sub-Menu" label="Students Data">
        
            <Link to="/StudentEliteRole">
              <MenuItem icon={<FaUserTie />}> Add Members </MenuItem>
            </Link>
            
              <Link to="/ManageEliteRole">
                <MenuItem icon={<BsDatabaseFillAdd />}> Manage Members </MenuItem>
              </Link>
          </SubMenu>

          <Link to="/AttendanceRole">
                <MenuItem icon={<AiOutlineAudit />}> Attendance </MenuItem>
              </Link>
         

          {/* <Link to="/Report">
                <MenuItem icon={<AiOutlineAreaChart />}> Reports </MenuItem>
          </Link> */}


{/* 

          <SubMenu icon={<FaUsers />} className="Sub-Menu" label="Employees">
              <Link to="/Users">
                    <MenuItem icon={<MdAccountCircle />}> Add Employees </MenuItem>
              </Link>
            
              <Link to="/ManageEmployees">
                <MenuItem icon={<BsDatabaseFillAdd />}> Manage Employees </MenuItem>
              </Link>
          </SubMenu> */}

          
        <SubMenu icon={<FaUsers />} className="Sub-Menu" label="Packages">
              <Link to="/PackageRole">
                    <MenuItem icon={<MdAccountCircle />}> Add Packages </MenuItem>
              </Link>
            
              <Link to="/ManagePackageRole">
                <MenuItem icon={<BsDatabaseFillAdd />}> Manage Packages </MenuItem>
              </Link>
          </SubMenu>

          </Menu>
        </Sidebar>

      <div
        className={`toggle-button ${display ? "" : "hidden"}`}
        onClick={toggleCollapse}
      >
        {collapse ? <FaToggleOn className="toggle-icon" /> : <FaToggleOff className="toggle-icon" />}
      </div>

      
    </div>
  );
};

export default SideBar;
