import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Users = () => {
  // preparation of useState , declare the variables
  const [formData, setFormData] = useState({
    USERNAME: "",
    PASSWORD: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    PHONE_NUMBER: "",
    WHATSAPP_PHONE_NUMBER: "",
    EMAIL_ID: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    START_DATE: "",
    END_DATE: "",
    EMPLOYEE_TYPE: "",
    STATUS: "",
    PAY_TYPE: "",
    PAY: "",
  });

  // update the input values to the variable
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // providing the api to insert the data into database , pass the values to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/users`, formData);
      console.log(response.data);
      console.log("successfully inserted");
      window.alert("successfully submitted");

      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      window.alert("unable to submit");
    }
  };

  const title = "Employee Details";

  // start of the creation of UI
  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Employee Details
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="UserName"
                    name="USERNAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="PASSWORD"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Frist Name"
                    name="FIRST_NAME"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="LastName"
                    name="LAST_NAME"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Whatsapp Number"
                    name="WHATSAPP_PHONE_NUMBER"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="EMAIL_ID"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Join Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Exit Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.END_DATE} // Bind the END_DATE state variable
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Employee Type</InputLabel>
                    <Select
                      label="Employee_Type"
                      name="EMPLOYEE_TYPE"
                      onChange={handleChange}
                    >
                      <MenuItem value="Admin">Admin </MenuItem>
                      <MenuItem value="Supervisor">Supervisor</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      onChange={handleChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="InActive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Pay Type"
                    name="PAY_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Pay"
                    name="PAY"
                    type=""
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <br />

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: "20%", backgroundColor: "#306ef3" }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Users;
