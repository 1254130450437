import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import { useLocation, useParams } from "react-router-dom";
import SideBar1 from "../Components/SideBar1";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StudentUpdate1 = () => {
  const { ID } = useParams(); // Destructure id from useParams
  const location = useLocation();
  const selectedLogo = new URLSearchParams(location.search).get("selectedLogo");

  const [packages, setPackages] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const [duration, setDuration] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [formData, setFormData] = useState({
    EMP_CODE: "",
    NAME: "",
    SURNAME: "",
    SEX: "",
    AGE: "",
    PHONE_NUMBER: "",
    GUARDIAN_NAME: "",
    GUARDIAN_PHONE_NUMBER: "",
    RELATIONSHIP: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    STATUS: "",
    PAUSE_START_DATE: null,
    PAUSE_END_DATE: null,
    COMMENTS: "",

    PACKAGE_TYPE: "",
    TIME_SLOT: "",
    DURATION: "",
    SPORT_TYPE: "Badminton",
    PRICE: "",
    ID: "",

    PACKAGE_ID: "",
    COURT: "",
    BULK_HOURS_ALLOCATED: "",
    BULK_HOURS_USED: "",
    BULK_PRICE: "",
    START_DATE: "",
    END_DATE: "",
    RENEWED: "",
    DISCOUNT: "",
    TOTAL_AMOUNT: "",
  });

  const [courts, setCourts] = useState({
    court_1: false,
    court_2: false,
    court_3: false,
    court_4: false,
    court_5: false,
  });

  // Fetch data from Express.js backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetch_package`)
      .then((response) => {
        const { packages } = response.data;
        const packageTypes = packages.map((pkg) => pkg.package_type);
        const timeSlot = packages.map((pkg) => pkg.time_slot);
        const duration = packages.map((pkg) => pkg.duration);

        setPackages(packages);

        setPackageType([...new Set(packageTypes)]); // Ensure uniqueness
        setDuration([...new Set(duration)]); // Ensure uniqueness
        setTimeSlot([...new Set(timeSlot)]); // Ensure uniqueness
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);
    try {
      const response = await axios.put(
        `${API_BASE_URL}/sky_student/${ID}`,
        formData
      );
      console.log(response.data);
      window.alert("Successfully Updated the student data");
      window.location.reload();
    } catch (error) {
      console.error("Error inserting data:", error);
      window.alert("Failed to UPDATE the data");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "START_DATE" || name === "DURATION") {
      const startDate =
        name === "START_DATE" ? new Date(value) : new Date(formData.START_DATE);
      const duration =
        name === "DURATION" ? parseInt(value) : parseInt(formData.DURATION);

      if (!isNaN(startDate.getTime()) && !isNaN(duration)) {
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + duration);

        // Subtract one day from the end date
        endDate.setDate(endDate.getDate() - 1);

        setFormData((prevFormData) => ({
          ...prevFormData,
          END_DATE: endDate.toISOString().split("T")[0],
        }));
      }
    }

    if (name === "DISCOUNT" || name === "PRICE" || name === "BULK_PRICE") {
      updateTotalAmount(name, value);
    }

    if (name === "STATUS") {
      setShowDates(value === "Pause"); // Update showDates based on STATUS
    }

     if (name === "PHONE_NUMBER") {
       setPhoneNumber(value);

       if (value.length > 10 || value.length < 10) {
         setPhoneNumberError(true);
       } else {
         setPhoneNumberError(false);
       }
     }

  };

  const updateTotalAmount = (name, value) => {
    const price = parseFloat(name === "PRICE" ? value : formData.PRICE);
    const bulkPrice = parseFloat(
      name === "BULK_PRICE" ? value : formData.BULK_PRICE
    );
    const discount = parseFloat(
      name === "DISCOUNT" ? value : formData.DISCOUNT
    );

    if (!isNaN(price) && !isNaN(discount)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        TOTAL_AMOUNT: (price - discount).toFixed(2),
      }));
    }

    if (!isNaN(bulkPrice) && !isNaN(discount)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        TOTAL_AMOUNT: (bulkPrice - discount).toFixed(2),
      }));
    }
  };

  const handleChangePackageType = async (event, newValue) => {
    try {
      setFormData((prevData) => ({
        ...prevData,
        PACKAGE_TYPE: newValue,
        TIME_SLOT: "", // Reset TIME_SLOT when PACKAGE_TYPE changes
        DURATION: "", // Reset DURATION when PACKAGE_TYPE changes
        PRICE: "", // Reset PRICE when PACKAGE_TYPE changes
        ID: newValue === "BULK" ? "1" : prevData.ID,
        PACKAGE_ID: newValue === "BULK" ? "1" : prevData.PACKAGE_ID,
      }));

      const response = await axios.post(`${API_BASE_URL}/api/package-type`, {
        packageType: newValue,
      });

      // const { data } = response;
      // setTimeSlot(data);

      const timeSlotData = response.data;
      setTimeSlot(timeSlotData);
    } catch (error) {
      console.error("Error fetching package names:", error);
    }

    // Send data to server if the package type is not BULK
    if (newValue !== "BULK") {
      sendDataToServer();
    }
  };

  const handleChangeTimeSlot = async (event, newValue) => {
    // Adjust the newValue if it's '5:30 - 7:00 AM'
    const adjustedTimeSlot =
      newValue === "5:30 - 7:00 AM" ? "6 - 7 AM" : newValue;

    setFormData((prevData) => ({
      ...prevData,
      TIME_SLOT: adjustedTimeSlot, // Keep the displayed value as selected
      DURATION: "", // Reset DURATION when TIME_SLOT changes
      PRICE: "", // Reset PRICE when TIME_SLOT changes
    }));

    // Fetch duration based on selected time slot and package type
    try {
      const response = await axios.post(`${API_BASE_URL}/api/timeSlot`, {
        packageType: formData.PACKAGE_TYPE,
        timeSlot: adjustedTimeSlot, // Send the adjusted time slot to the server
      });

      const durationData = response.data;
      setDuration(durationData);
    } catch (error) {
      console.error("Error fetching duration data:", error);
    }

    // Send data to the server if the package type is not BULK
    if (formData.PACKAGE_TYPE !== "BULK") {
      sendDataToServer();
    }
  };

  const handleChangeDuration = async (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      DURATION: newValue,
      PRICE: "", // Reset PRICE when DURATION changes
    }));

    // Send data to server if the package type is not BULK
    if (formData.PACKAGE_TYPE !== "BULK") {
      sendDataToServer();
    }
  };

  const sendDataToServer = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/sky_price`, formData);

      const { price, ID } = response.data;

      console.log("get price :", price);
      console.log("get ID :", ID);

      setFormData((prevFormData) => ({
        ...prevFormData,
        PRICE: price,
        ID: ID,
        PACKAGE_ID: ID,
      }));
    } catch (error) {
      console.error("Error retrieving data:", error);
    }
  };

  const fetchCourtData = async () => {
    console.log("fetchCourtData ");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/sky_courts`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      const data = response.data;
      setCourts(data); // Update the state with the server response
    } catch (error) {
      console.error("Error fetching court data:", error);
    }
  };

  // Example usage
  useEffect(() => {
    fetchCourtData();
  }, [formData]);

  useEffect(() => {
    if (formData.PACKAGE_TYPE && formData.TIME_SLOT && formData.DURATION) {
      sendDataToServer();
    }
  }, [formData.PACKAGE_TYPE, formData.TIME_SLOT, formData.DURATION]);

  console.log("THE PACKAGE ID:", formData.ID);
  console.log("THE PACKAGE ID:", formData.PACKAGE_ID);

  console.log(formData);

  const [showDates, setShowDates] = useState(false);

  useEffect(() => {
    // Fetch existing data based on ID and set the form data
    axios
      .get(`${API_BASE_URL}/fetchSky/${ID}`)
      .then((response) => {
        setFormData(response.data);
        console.log(response.data);
        const fetchedData = response.data;
        // Ensure date fields are correctly formatted
        if (fetchedData.START_DATE) {
          fetchedData.START_DATE = new Date(fetchedData.START_DATE)
            .toISOString()
            .split("T")[0];
        }

        if (fetchedData.END_DATE) {
          fetchedData.END_DATE = new Date(fetchedData.END_DATE)
            .toISOString()
            .split("T")[0];
        }

        if (fetchedData.PAUSE_START_DATE) {
          fetchedData.PAUSE_START_DATE = new Date(fetchedData.PAUSE_START_DATE)
            .toISOString()
            .split("T")[0];
        }

        if (fetchedData.PAUSE_END_DATE) {
          fetchedData.PAUSE_END_DATE = new Date(fetchedData.PAUSE_END_DATE)
            .toISOString()
            .split("T")[0];
        }

        setFormData(fetchedData);
        setShowDates(fetchedData.STATUS === "Pause"); // Set showDates based on STATUS
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [ID]);

  const title = "Student Update";

  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar1 />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="ID"
                    variant="outlined"
                    name="EMP_CODE"
                    value={formData.emp_code}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">BD</InputAdornment>
                      ),
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="NAME"
                    variant="outlined"
                    value={formData.NAME}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Sex</InputLabel>
                    <Select
                      label="Gender"
                      name="SEX"
                      value={formData.SEX}
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Age"
                    name="AGE"
                    type="number"
                    variant="outlined"
                    value={formData.AGE}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    value={phoneNumber}
                    error={phoneNumberError}
                    helperText={phoneNumberError ? "Invalid Entry." : ""}
                    
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian Name"
                    name="GUARDIAN_NAME"
                    variant="outlined"
                    value={formData.GUARDIAN_NAME}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian PhNo."
                    name="GUARDIAN_PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    value={formData.GUARDIAN_PHONE_NUMBER}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Relation</InputLabel>
                    <Select
                      label="Relation"
                      name="RELATIONSHIP"
                      value={formData.RELATIONSHIP}
                      onChange={handleChange}
                    >
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Self">Self</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    variant="outlined"
                    value={formData.ADDRESS}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    variant="outlined"
                    value={formData.CITY}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    variant="outlined"
                    value={formData.STATE}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      value={formData.STATUS}
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active</MenuItem>
                      <MenuItem value="Pause">Pause</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {showDates && (
                  <>
                    <Grid item xs={11} sm={5} md={4} lg={2}>
                      <TextField
                        fullWidth
                        label="Pause Start Date"
                        name="PAUSE_START_DATE"
                        type="date"
                        variant="outlined"
                        value={formData.PAUSE_START_DATE}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={2}>
                      <TextField
                        fullWidth
                        label="Pause End Date"
                        name="PAUSE_END_DATE"
                        type="date"
                        variant="outlined"
                        value={formData.PAUSE_END_DATE}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={11} sm={5} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="Comments"
                    name="COMMENTS"
                    type="text"
                    variant="outlined"
                    value={formData.COMMENTS}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                {/* ////////////////////////////////////////////////////////////////// */}
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Class Information
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="package-type"
                    options={packageType}
                    value={formData.PACKAGE_TYPE}
                    onChange={(event, newValue) => {
                      handleChangePackageType(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.PACKAGE_TYPE}
                        label="Package Type"
                        required
                      />
                    )}
                    freeSolo
                  />
                </Grid>

                {formData.PACKAGE_TYPE === "BULK" ? (
                  <>
                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Bulk Hours Allocated"
                        name="BULK_HOURS_ALLOCATED"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.BULK_HOURS_ALLOCATED}
                        required
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Bulk Hours Used"
                        name="BULK_HOURS_USED"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.BULK_HOURS_USED}
                        required
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Bulk Price"
                        name="BULK_PRICE"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.BULK_PRICE}
                        required
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="time-slot"
                        options={timeSlot}
                        value={
                          formData.TIME_SLOT === "6 - 7 AM"
                            ? "5:30 - 7:00 AM"
                            : formData.TIME_SLOT
                        }
                        onChange={handleChangeTimeSlot}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={
                              formData.TIME_SLOT === "6 - 7 AM"
                                ? "5:30 - 7:00 AM"
                                : formData.TIME_SLOT
                            }
                            label="Time Slot"
                            required
                          />
                        )}
                        freeSolo
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <Autocomplete
                        disablePortal
                        id="duration"
                        options={duration}
                        value={formData.DURATION}
                        onChange={handleChangeDuration}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.DURATION}
                            label="Duration"
                            required
                          />
                        )}
                        freeSolo
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <TextField
                        fullWidth
                        label="Price"
                        name="PRICE"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.PRICE}
                        required
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Court</InputLabel>
                        <Select
                          label="Court"
                          name="COURT"
                          onChange={handleChange}
                          value={formData.COURT}
                          required
                        >
                          <MenuItem value="1" disabled={!courts.court_1}>
                            Court 1
                          </MenuItem>
                          <MenuItem value="2" disabled={!courts.court_2}>
                            Court 2
                          </MenuItem>
                          <MenuItem value="3" disabled={!courts.court_3}>
                            Court 3
                          </MenuItem>
                          <MenuItem value="4" disabled={!courts.court_4}>
                            Court 4
                          </MenuItem>
                          <MenuItem value="5" disabled={!courts.court_5}>
                            Court 5
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Sport Type"
                    name="SPORT_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.SPORT_TYPE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Renewed</InputLabel>
                    <Select
                      label="Renewed"
                      name="RENEWED"
                      value={formData.RENEWED}
                      onChange={handleChange}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Discount"
                    name="DISCOUNT"
                    variant="outlined"
                    value={formData.DISCOUNT}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Total Amount"
                    name="TOTAL_AMOUNT"
                    variant="outlined"
                    value={formData.TOTAL_AMOUNT}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.START_DATE}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="End Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.END_DATE} // Bind the END_DATE state variable
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "20% !important",
                      backgroundColor: " #306ef3!important",
                    }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default StudentUpdate1;
