import React, { useState, useContext } from "react";
import "../ComponentsCSS/Login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "./satyalogo.png";
import { AuthContext } from "./AuthProvider";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Login = () => {
  const navigate = useNavigate();

  axios.defaults.withCredentials = true;
  const { login } = useContext(AuthContext);

  const [values, setValues] = useState({
    EMAIL_ID: "",
    PASSWORD: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, values);

      if (response.data.Status === "Success") {
        const userData = response.data.data;
        const userRole = response.data.data.EMPLOYEE_TYPE;
        const token = response.data.token;

        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("userRole", userRole);
        localStorage.setItem("token", token); // Store token in localStorage

        login(token); // Use login function to store token
      

        if (userRole === "Admin") {
          navigate("/IntroScreen", {
            state: { name: response.data.data.USERNAME },
          });
        } else if (userRole === "Supervisor") {
          navigate("/IntroScreen1", {
            state: { name: response.data.data.USERNAME },
          });
        } else if (userRole === "Trainer") {
          navigate("/dashboard", {
            state: { name: response.data.data.USERNAME },
          });
        }
      } else {
        alert(response.data.Message);
      }
    } catch (error) {
      console.log(error);
      alert("An error occurred while logging in");
    }
  };

  return (
    <div className="login">
      <div className="container" id="container">
        <div className="form-container sign-in-container">
          <form className="loginForm" onSubmit={handleSubmit}>
            <img id="logo" src={logo} alt="elite_logo" />
            <h1>Sign in</h1>

            <input
              onChange={handleChange}
              type="email"
              placeholder="email"
              name="EMAIL_ID"
              value={values.EMAIL_ID}
              required
            />
            <input
              onChange={handleChange}
              type="password"
              placeholder="password"
              name="PASSWORD"
              value={values.PASSWORD}
              required
            />
            <button type="submit">submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
