import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  colors,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useLocation, useParams, Link } from "react-router-dom";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import TextField from "@mui/material/TextField";
import "../ComponentsCSS/ManageButton.css";
import { MdDelete } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import "../ComponentsCSS/ManageTable.css";
 import { useInView } from "react-intersection-observer";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function DsrTable() {
  const { status } = useParams();
  const location = useLocation();
  console.log(status);
  // Validate if the status matches the expected format "YYYY-MM"
  const isValidStatusFormat = /^\d{4}-\d{2}$/.test(status);

  // Check if status is "active" or a valid date format
  let initialStatusActive = "";
  let selectedMonthStatus = "";

  if (status === "active") {
    initialStatusActive = "Active";
  } else if (isValidStatusFormat) {
    selectedMonthStatus = status;
  }

  // Use the state variables as needed in your component
  console.log("Initial Status Active:", initialStatusActive);
  console.log("Selected Month Status:", selectedMonthStatus);

  const [userData, setUserData] = useState([]);
  const elementRef = useRef(null);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchcodeQuery, setSearchcodeQuery] = useState("");
  const [arrowDisable, setArrowDisable] = useState(true);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [studentCode, setStudentCode] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(selectedMonthStatus);
  const [statusFilter, setStatusFilter] = useState(initialStatusActive); // State for status filter
  const [visibleCount, setVisibleCount] = useState(10); // Initial number of visible rows
  const [isLoading, setIsLoading] = useState(false); // Flag to prevent multiple loading requests
  useEffect(() => {
    const table = elementRef.current;

    const handleScroll = () => {
      // Load more data when user scrolls near the bottom
      if (
        table.scrollTop + table.clientHeight >= table.scrollHeight - 20 &&
        !isLoading
      ) {
        setIsLoading(true);
        setTimeout(() => {
          setVisibleCount((prevCount) => prevCount + 10); // Increase visible count
          setIsLoading(false);
        }, 1000); // Simulated delay before loading more data
      }
    };

    table.addEventListener("scroll", handleScroll);

    return () => {
      table.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading]); // Dependency on isLoading to prevent multiple requests

  console.log(selectedMonth);

  const selectedLogo = new URLSearchParams(location.search).get("selectedLogo");

  // Fetch operation to get data from the database
  useEffect(() => {
    axios 
      .get(`${API_BASE_URL}/fetchstudent`)
      .then((res) => {
        setUserData(res.data);
        console.log(res.data);
        setFilteredUserData(res.data); // Filtered data with all sales data
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // Horizontal scroll functionality
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  // Styled table cells and rows
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
      verticalAlign: "top",
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px !important",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // Filter Condition based on start date and end date
  useEffect(() => {
    let filteredData = userData;

    if (statusFilter !== "") {
      filteredData = filteredData.filter((row) => row.STATUS === statusFilter);
    }

    setFilteredUserData(filteredData);

    if (startDateFilter && endDateFilter) {
      // Filter data based on start date range
      filteredData = filteredData.filter((row) => {
        const startDate = new Date(row.START_DATE);
        return startDate >= startDateFilter && startDate <= endDateFilter;
      });
    }

    // Filter data based on selected time slot
    if (selectedTimeSlot !== "") {
      filteredData = filteredData.filter(
        (row) => row.TIME_SLOT === selectedTimeSlot
      );
    }

    // Filter by selected month
    if (selectedMonth !== "") {
      // Parse selectedMonth to create a date object
      const [year, month] = selectedMonth.split("-");
      const selectedDate = new Date(parseInt(year), parseInt(month) - 1, 1);

      // Filter data based on the selected month
      filteredData = filteredData.filter((row) => {
        const startDate = new Date(row.START_DATE);
        return (
          startDate.getMonth() === selectedDate.getMonth() &&
          startDate.getFullYear() === selectedDate.getFullYear()
        );
      });
    }

    // Filter data based on search query
    if (searchQuery.trim() !== "") {
      filteredData = filteredData.filter((row) =>
        (row.NAME ? row.NAME.toLowerCase() : "").includes(
          searchQuery.toLowerCase().trim()
        )
      );
    }
    // Filter data based on search query
    if (searchcodeQuery.trim() !== "") {
      filteredData = filteredData.filter((row) =>
        (row.emp_code ? row.emp_code.toLowerCase() : "").includes(
          searchcodeQuery.toLowerCase().trim()
        )
      );
      console.log("Filtered by search query:", filteredData);
    }

    setFilteredUserData(filteredData);
  }, [
    searchcodeQuery,
    searchQuery,
    startDateFilter,
    endDateFilter,
    selectedTimeSlot,
    selectedMonth,
    userData,
    statusFilter,
  ]);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    console.log("Search Input Change:", value);
    setSearchQuery(value);
  };

  const handleStudentCodeChange = (event) => {
    const value = event.target.value;
    console.log("Search Input Change:", value);
    setSearchcodeQuery(value);
  };

  // Function to handle change in start date filter
  const handleMonthFilterChange = (e) => {
    const selectedMonth = e.target.value;
    console.log("Selected Month:", selectedMonth);
    setSelectedMonth(selectedMonth);
    // Assuming you want to filter based on the selected month
    // Create a date object with the selected month
  };
  useEffect(() => {
    console.log(selectedMonth);
  }, []);

  const handleStartDateFilterChange = (date) => {
    setStartDateFilter(date);
  };

  // Function to handle change in end date filter
  const handleEndDateFilterChange = (date) => {
    setEndDateFilter(date);
  };

  // Function to handle change in selected time slot
  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  // Scroll to top functionality
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return "-" if the date string is null or empty
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "-" : date.toLocaleDateString("en-IN");
  };

  const title = "Student Table";

  // const handleDownload = (rowData) => {
  //   axios
  //     .post(`${API_BASE_URL}/pdf`, { rowData }, { responseType: "blob" }) // Set responseType to "blob" to receive binary data
  //     .then((res) => {
  //       console.log("Invoice download request successful");
  //       const blob = new Blob([res.data], { type: "application/pdf" });
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "details.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //       console.log("PDF downloaded successfully");
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading invoice:", error);
  //     });
  // };

  const handleDownload = (rowData) => {
    axios
      .post(`${API_BASE_URL}/pdf`, { rowData }, { responseType: "blob" }) // Set responseType to "blob" to receive binary data
      .then((res) => {
        console.log("Invoice download request successful");
        const blob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        // Sanitize the student name for the file name
        const sanitizedStudentName = rowData.NAME.replace(
          /[^a-z0-9]/gi,
          " "
        ).toLowerCase();
        const fileName = `${sanitizedStudentName} Bill Details.pdf`;

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        console.log("PDF downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading invoice:", error);
      });
  };

  const handleDownloadMembership = (rowData) => {
    axios
      .post(
        `${API_BASE_URL}/membership_pdf`,
        { rowData },
        { responseType: "blob" }
      ) // Set responseType to "blob" to receive binary data
      .then((res) => {
        console.log("Invoice download request successful");
        const blob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        // Sanitize the student name for the file name
        const sanitizedStudentName = rowData.NAME.replace(
          /[^a-z0-9]/gi,
          " "
        ).toLowerCase();
        const fileName = `${sanitizedStudentName} MemberShip Details.pdf`;
        console.log(fileName);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        console.log("PDF downloaded successfully");
      })
      .catch((error) => {
        console.error("Error downloading invoice:", error);
      });
  };

  // const handleDelete = async (id) => {
  //   try {
  //     const response = await axios.delete(
  //       `http://localhost:5002/student/${id}`
  //     );
  //     console.log(response.data.message);
  //     setUserData(userData.filter((student) => student.ID !== id));
  //     setFilteredUserData(
  //       filteredUserData.filter((student) => student.ID !== id)
  //     );
  //   } catch (err) {
  //     console.error("Error deleting student:", err);
  //     alert("An error occurred while deleting the student");
  //   }
  // };

  const TIME_SLOT = [
    "06 - 07 AM",
    "07 - 08 AM",
    "08 - 09 AM",
    "09 - 10 AM",
    "10 - 11 AM",
    "11 - 12 PM",
    "12 - 01 PM",
    "01 - 02 PM",
    "03 - 04 PM",
    "04 - 05 PM",
    "05 - 06 PM",
    "06 - 07 PM",
    "07 - 08 PM",
    "08 - 09 PM",
    "09 - 10 PM",
  ];

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const tableRef = useRef(null);

  return (
    <div className="background">
      <Header title={title} />

      <div className="static-table">
        <SideBar selectedLogo={selectedLogo} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main" className="mainn">
            <div id="searchTab">
              <div className="Search">
                <Stack spacing={4}>
                  <Stack
                    spacing={2}
                    direction={"row"}
                    style={{ width: "100%" }}
                  >
                    <Grid item xs={11} sm={5} md={4} lg={4}>
                      <TextField
                        style={{ width: "150px", marginTop: "0px" }}
                        label="Search Student"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />
                    </Grid>
                    <Grid item xs={11} sm={5} md={4} lg={4}>
                      <TextField
                        id="start-date"
                        label="Start Date"
                        type="date"
                        defaultValue=""
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleStartDateFilterChange(new Date(e.target.value))
                        }
                      />
                    </Grid>
                    <Grid item xs={11} sm={5} md={4} lg={4}>
                      <TextField
                        id="end-date"
                        label="End Date"
                        type="date"
                        defaultValue=""
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleEndDateFilterChange(new Date(e.target.value))
                        }
                      />
                    </Grid>
                    <Grid item xs={11} sm={5} md={4} lg={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel>Time_Slot</InputLabel>
                        <Select
                          label="Time Slot"
                          fullWidth
                          value={selectedTimeSlot}
                          onChange={handleTimeSlotChange}
                          name="TIME_SLOT"
                          variant="outlined"
                          style={{ width: "150px" }}
                        >
                          <MenuItem value="">All</MenuItem>
                          {TIME_SLOT.map((slot, index) => (
                            <MenuItem key={index} value={slot}>
                              {slot}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={4}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "170px" }}
                      >
                        <InputLabel>Status</InputLabel>
                        <Select
                          label="Status"
                          value={statusFilter}
                          onChange={handleStatusFilterChange}
                        >
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="In Active">In Active</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Stack>
                </Stack>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "38px",
                }}
                id="arrowButton"
              >
                <Grid item xs={11} sm={5} md={4} lg={4}>
                  <TextField
                    id="student_code"
                    label="Student Code"
                    type="text"
                    defaultValue=""
                    onChange={handleStudentCodeChange}
                    sx={{
                      marginTop: "7px",
                      marginRight: "58px",
                      width: "175px",
                    }}
                  />
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={4}>
                  <TextField
                    id="month"
                    label="Month"
                    type="month" // Use lowercase "month" for the type attribute
                    defaultValue=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={selectedMonth}
                    onChange={handleMonthFilterChange}
                    sx={{
                      marginTop: "7px",
                      marginRight: "58px",
                      width: "175px",
                    }}
                  />
                </Grid>

                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                    height: "35px",
                    marginTop: "12px",
                    backgroundColor: "#306ef3",
                    color: "white",
                  }}
                  component={Link}
                  to="/StudentData"
                >
                  {" "}
                  Add{" "}
                </Button>
                <Button
                  variant="outlined"
                  sx={{ margin: "10px 10px 10px 40px" }}
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 100, 305, -10);
                  }}
                  disabled={arrowDisable}
                >
                  <FaArrowLeft />
                </Button>

                <Button
                  sx={{ margin: "10px 10px 10px 0px" }}
                  variant="outlined"
                  onClick={() => {
                    handleHorizantalScroll(elementRef.current, 100, 305, 10);
                  }}
                >
                  <FaArrowRight />
                </Button>
              </div>
            </div>

            <TableContainer
              component={Paper}
              ref={elementRef}
              id="scroll"
              sx={{ maxHeight: 600, overflow: "auto" }}
            >
              <Table
                ref={tableRef}
                aria-label="customized table sticky header"
                stickyHeader
              >
                <TableHead
                  sx={{
                    ".MuiTableCell-root": { fontWeight: "600" },
                  }}
                >
                  <TableRow>
                    <StyledTableCell>Student Id</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Gender</StyledTableCell>
                    <StyledTableCell>Age</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    <StyledTableCell>Enrollment</StyledTableCell>
                    <StyledTableCell>Aadhar Number</StyledTableCell>
                    <StyledTableCell>Guardian Name</StyledTableCell>
                    <StyledTableCell>Guardian Phone Number</StyledTableCell>
                    <StyledTableCell>Relationship</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>City</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Pause Start Date</StyledTableCell>
                    <StyledTableCell>Pause End Date</StyledTableCell>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Package Name</StyledTableCell>
                    <StyledTableCell>Week Days</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell>Discount</StyledTableCell>
                    <StyledTableCell>Total Amount</StyledTableCell>
                    <StyledTableCell>Start Date</StyledTableCell>
                    <StyledTableCell>End Date</StyledTableCell>
                    <StyledTableCell>Time_Slot</StyledTableCell>
                    <StyledTableCell>Comments</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                    <StyledTableCell>Bill PDF</StyledTableCell>
                    <StyledTableCell>Membership PDF</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUserData.slice(0, visibleCount).map((row) => (
                    <StyledTableRow key={row.ID}>
                      <StyledTableCell>{row.emp_code}</StyledTableCell>
                      <StyledTableCell>{row.NAME}</StyledTableCell>
                      <StyledTableCell>{row.SEX}</StyledTableCell>
                      <StyledTableCell>{row.AGE}</StyledTableCell>
                      <StyledTableCell>{row.PHONE_NUMBER}</StyledTableCell>
                      <StyledTableCell>{row.ENROLLMENT}</StyledTableCell>
                      <StyledTableCell>{row.AADHAR_NUMBER}</StyledTableCell>
                      <StyledTableCell>{row.GUARDIAN_NAME}</StyledTableCell>
                      <StyledTableCell>
                        {row.GUARDIAN_PHONE_NUMBER}
                      </StyledTableCell>
                      <StyledTableCell>{row.RELATIONSHIP}</StyledTableCell>
                      <StyledTableCell>{row.ADDRESS}</StyledTableCell>
                      <StyledTableCell>{row.CITY}</StyledTableCell>

                      <StyledTableCell>{row.STATUS}</StyledTableCell>
                      <StyledTableCell>
                        {formatDate(row.PAUSE_START_DATE)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDate(row.PAUSE_END_DATE)}
                      </StyledTableCell>

                      <StyledTableCell>{row.CATEGORY}</StyledTableCell>
                      <StyledTableCell>{row.PACKAGE_NAME}</StyledTableCell>
                      <StyledTableCell>{row.WEEK_DAYS}</StyledTableCell>

                      <StyledTableCell>{row.PRICE}</StyledTableCell>
                      <StyledTableCell>{row.DISCOUNT}</StyledTableCell>
                      <StyledTableCell>{row.TOTAL_AMOUNT}</StyledTableCell>

                      <StyledTableCell>
                        {formatDate(row.START_DATE)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDate(row.END_DATE)}
                      </StyledTableCell>
                      <StyledTableCell>{row.TIME_SLOT}</StyledTableCell>
                      <StyledTableCell>{row.COMMENTS}</StyledTableCell>

                      <StyledTableCell>
                        <Stack direction="row" spacing={1}>
                          <Button
                            className="ManageButton"
                            variant="contained"
                            color="secondary"
                            size="small"
                          >
                            <Link to={`/UpdateStudent/${row.ID}`}>
                              {" "}
                              <EditCalendarIcon
                                style={{ height: "15px", color: "white" }}
                              />
                            </Link>
                          </Button>
                        </Stack>
                      </StyledTableCell>

                      <StyledTableCell>
                        <Stack direction="row" spacing={1}>
                          <Button
                            className="ManageButton"
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleDownload(row)}
                          >
                            <IoMdDownload
                              style={{ height: "20px", color: "white" }}
                            />
                          </Button>
                        </Stack>
                      </StyledTableCell>

                      {/* <Button
                              className="ManageButton"
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={() => handleDelete(row.ID)} // Adding handleDelete function call
                            > 
                              <MdDelete
                                style={{ height: "20px", color: "white" }}
                              />
                            </Button> */}

                      <StyledTableCell>
                        <Stack direction="row" spacing={1}>
                          <Button
                            className="ManageButton"
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => handleDownloadMembership(row)}
                          >
                            <IoMdDownload
                              style={{ height: "20px", color: "white" }}
                            />
                          </Button>
                          {/* <Button
                              className="ManageButton"
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={() => handleDelete(row.ID)} // Adding handleDelete function call
                            > 
                              <MdDelete
                                style={{ height: "20px", color: "white" }}
                              />
                            </Button> */}
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{
                  backgroundColor: "#205295",
                  color: "white",
                  margin: "30px 30px 30px 30px",
                }}
                onClick={handleScrollToTop}
              >
                Back to Top
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default DsrTable;

