import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import "../ComponentsCSS/AdminDashboard.css";

import axios from "axios";
import SideBar1 from "../Components/SideBar1";
import Header1 from "../Components/Header1";
import { useParams } from "react-router-dom";
// import { useSearchParams } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UpdatePackage1 = () => {
  const { ID } = useParams(); // Destructure id from useParams

  // preparation of useState , declare the variables
  const [formData, setFormData] = useState({
    PACKAGE_TYPE: "",
    TIME_SLOT: "",
    DURATION: "",
    PRICE: "",
    SPORT_TYPE: "Badminton",
    STATUS: "",
  });

  // update the input values to the variable
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // providing the api to insert the data into database , pass the values to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${API_BASE_URL}/sky_package/${ID}`,
        formData
      );
      console.log(response.data);
      console.log("successfully inserted");
      window.alert("successfully updated");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      window.alert("unable to submit");
    }
  };

  useEffect(() => {
    // Fetch existing data based on ID and set the form data
    axios
      .get(`${API_BASE_URL}/sky_package/${ID}`)
      .then((response) => {
        setFormData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [ID]);

  const getTimeSlots = () => {
    if (
      formData.PACKAGE_TYPE === "MEMBERSHIP" ||
      formData.PACKAGE_TYPE === "FULL COURT"
    ) {
      return TIME_SLOT;
    } else if (formData.PACKAGE_TYPE === "COACHING") {
      return TIME_SLOT1;
    }
    return [];
  };

  const TIME_SLOT = [
    "5 - 6 AM",
    "6 - 7 AM",
    "7 - 8 AM",
    "8 - 9 AM",
    "9 - 10 AM",
    "7 - 8 PM",
    "8 - 9 PM",
    "9 - 10 PM",
  ];

  const TIME_SLOT1 = [
    "5:30 - 7:00 AM",
    "4:00 - 5:30 PM",
    "5:00 - 6:30 PM",
    "5:30 - 7:00 PM",
  ];

  const PACKAGE_OPTIONS = ["MEMBERSHIP", "FULL COURT", "COACHING", "BULK"];

  const title = "Package Update";

  // start of the creation of UI
  return (
    <div className="background">
      <Header1 title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar1 />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Package Update
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Package Type</InputLabel>
                    <Select
                      label="Package Type"
                      fullWidth
                      value={formData.PACKAGE_TYPE}
                      onChange={handleChange}
                      name="PACKAGE_TYPE"
                      variant="outlined"
                    >
                      {PACKAGE_OPTIONS.map((packageOption, index) => (
                        <MenuItem key={index} value={packageOption}>
                          {packageOption}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Time Slot</InputLabel>
                    <Select
                      label="Time Slot"
                      fullWidth
                      value={formData.TIME_SLOT}
                      onChange={handleChange}
                      name="TIME_SLOT"
                      variant="outlined"
                    >
                      {getTimeSlots().map((slot, index) => (
                        <MenuItem key={index} value={slot}>
                          {slot}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Duration</InputLabel>
                    <Select
                      label="Duration"
                      name="DURATION"
                      value={formData.DURATION}
                      onChange={handleChange}
                    >
                      <MenuItem value="1">1 Month</MenuItem>
                      <MenuItem value="6">6 Months</MenuItem>
                      <MenuItem value="12">12 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="PRICE"
                    variant="outlined"
                    value={formData.PRICE}
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="SPORT TYPE"
                    name="SPORT_TYPE"
                    variant="outlined"
                    value={formData.SPORT_TYPE}
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      value={formData.STATUS}
                      onChange={handleChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <br />

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: "20%", backgroundColor: "#306ef3" }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default UpdatePackage1;
