import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MdDelete } from "react-icons/md";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ManageEmployees() {
  const [userData, setUserData] = useState([]);
  const elementRef = useRef(null);
  const [filteredUserData, setFilteredUserData] = useState(userData);
  const [searchQuery, setSearchQuery] = useState("");
  const [arrowDisable, setArrowDisable] = useState(true);
  const [statusQuery, setStatusQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState(""); // State for status filter

  // start providing the fetch operation to take data from database
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetchuser`)
      .then((res) => {
        setUserData(res.data);
        console.log(res.data);
        setFilteredUserData(res.data); // filtered data with all sales data
        // window.alert("fetching of data done");
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        // window.alert("unable to fetch the data");
        // Handle error (e.g., display a message to the user)
      });
  }, []);

  // end of display of the data

  // start of Filter sales data based on search query

  // Filter user data based on search query and status
  useEffect(() => {
    let filteredData = userData;

    if (searchQuery.trim() !== "") {
      filteredData = filteredData.filter((row) =>
        row.USERNAME.toLowerCase().includes(searchQuery.toLowerCase().trim())
      );
    }

    if (statusFilter !== "") {
      filteredData = filteredData.filter((row) => row.STATUS === statusFilter);
    }

    setFilteredUserData(filteredData);
  }, [searchQuery, statusFilter, userData]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // start providing the arrow slide option to the table

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };
  // end of providing sliding to the table

  // providing the color to the tables
  // below for the header
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
      verticalAlign: "top",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px !important",
    },
  }));

  // below one add color to the table body
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  // end of adding color to the table

  // start  To scroll from the bottom to top we go to below function
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };
  // End to scroll to bottom to top

  // const handleDelete = async (id) => {
  //   try {
  //     const response = await axios.delete(`http://localhost:5002/api/users/${id}`);
  //     console.log(response.data.message);

  //     setUserData(userData.filter((users) => users.ID !== id));
  //     setFilteredUserData(
  //       filteredUserData.filter((users) => users.ID !== id)
  //     );

  //   } catch (err) {
  //     console.error("Error deleting student:", err);
  //     alert("An error occurred while deleting the student");
  //   }
  // };

  const title = "Employee Table";

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="background">
      <Header title={title} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar />

        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  <TextField
                    style={{ width: "224px" }}
                    label="Search Employee Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />

                  <FormControl variant="outlined" style={{ width: "224px" }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="InActive">In Active</MenuItem>
                    </Select>
                  </FormControl>

                  <Button
                    variant="contained"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      height: "35px",
                      marginTop: "4px",
                      backgroundColor: "#306ef3",
                      color: "white",
                    }}
                    component={Link}
                    to="/Users"
                  >
                    {" "}
                    Add{" "}
                  </Button>
                </Stack>
              </Stack>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                sx={{ margin: "10px 10px 10px 10px" }}
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, -10);
                }}
                disabled={arrowDisable}
              >
                <FaArrowLeft />
              </Button>
              <Button
                sx={{ margin: "10px 10px 10px 0px" }}
                variant="outlined"
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, 10);
                }}
              >
                <FaArrowRight />
              </Button>
            </div>

            <TableContainer component={Paper} ref={elementRef}>
              <Table>
                <TableHead
                  sx={{
                    ".MuiTableCell-root": { fontWeight: "600" },
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>Username</StyledTableCell>
                    <StyledTableCell>Password</StyledTableCell>
                    <StyledTableCell>First Name</StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    <StyledTableCell>Whatsapp Number</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>City</StyledTableCell>
                    <StyledTableCell>State</StyledTableCell>
                    <StyledTableCell>Employee Type</StyledTableCell>
                    <StyledTableCell>Start Date</StyledTableCell>
                    <StyledTableCell>End Date</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Pay Type</StyledTableCell>
                    <StyledTableCell>Pay</StyledTableCell>

                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(filteredUserData) &&
                    filteredUserData.map((row) => (
                      <StyledTableRow key={row.ID}>
                        <StyledTableCell>{row.ID}</StyledTableCell>
                        <StyledTableCell>{row.USERNAME}</StyledTableCell>
                        <StyledTableCell>{row.PASSWORD}</StyledTableCell>
                        <StyledTableCell>{row.FIRST_NAME}</StyledTableCell>
                        <StyledTableCell>{row.LAST_NAME}</StyledTableCell>
                        <StyledTableCell>{row.PHONE_NUMBER}</StyledTableCell>

                        <StyledTableCell>
                          {row.WHATSAPP_PHONE_NUMBER}
                        </StyledTableCell>
                        <StyledTableCell>{row.EMAIL_ID}</StyledTableCell>
                        <StyledTableCell>{row.ADDRESS}</StyledTableCell>

                        <StyledTableCell>{row.CITY}</StyledTableCell>
                        <StyledTableCell>{row.STATE}</StyledTableCell>

                        <StyledTableCell>{row.EMPLOYEE_TYPE}</StyledTableCell>
                        <StyledTableCell>
                          {formatDate(row.START_DATE)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatDate(row.END_DATE)}
                        </StyledTableCell>
                        <StyledTableCell>{row.STATUS}</StyledTableCell>
                        <StyledTableCell>{row.PAY_TYPE}</StyledTableCell>
                        <StyledTableCell>{row.PAY}</StyledTableCell>

                        <StyledTableCell>
                          <Button
                            className="ManageButton"
                            variant="contained"
                            color="secondary"
                            size="small"
                          >
                            <Link to={`/UpdateEmployee/${row.ID}`}>
                              {" "}
                              <EditCalendarIcon
                                style={{ height: "15px", color: "white" }}
                              />
                            </Link>
                          </Button>

                          {/* <Button
                          className="ManageButton"
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => handleDelete(row.ID)} // Adding handleDelete function call
                        >
                          <MdDelete style={{ height: "20px", color: "white" }} />
                        </Button>  */}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleScrollToTop}
            // style={{width: "20%", backgroundColor: "#209e9d "}}
            style={{
              width: "20%",
              backgroundColor: "#306ef3 ",
              marginTop: "10px",
            }}
          >
            Scroll To Top
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default ManageEmployees;
