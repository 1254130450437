import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";

import Header from "../Components/Header";
import SideBarEliteRole from "../Ellite_Role/SideBarEliteRole";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import "../ComponentsCSS/ManageButton.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function AttendanceRole() {
  const [attendanceData, setAttendanceData] = useState([]);

  const [searchQuery, setSearchQuery] = useState(""); // State for employee code search query
  const [dateQuery, setDateQuery] = useState(""); // State for date search query

  // Fetch attendance data from the database
  // Fetch attendance data from the database
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetchAttendance`)
      .then((res) => {
        console.log(res.data);
        setAttendanceData(res.data); // Assuming res.data includes student_name
      })
      .catch((error) => {
        console.error("Error fetching attendance data:", error);
      });
  }, []);

  // Function to format date and time separately
  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toISOString().split("T")[0]; // Extract date part

    // Extract time part and split into hours, minutes, and seconds
    const timeString = dateTime.toISOString().split("T")[1].split(".")[0];
    const [hours, minutes, seconds] = timeString.split(":");

    // Convert hours to 12-hour format and determine AM/PM
    let formattedHours = parseInt(hours, 10);
    let period = "AM";
    if (formattedHours >= 12) {
      formattedHours -= 12;
      period = "PM";
    }
    if (formattedHours === 0) {
      formattedHours = 12; // 12 AM
    }

    // Format time as HH:MM AM/PM
    const time = `${formattedHours}:${minutes}:${seconds} ${period}`;

    return { date, time };
  };

  // Preprocess attendance data to combine entries for each employee and date
  const preprocessAttendanceData = () => {
    const combinedData = {};
    attendanceData.forEach((entry) => {
      const key = `${entry.emp_code}_${entry.punch_time.split("T")[0]}`;
      if (!combinedData[key]) {
        combinedData[key] = [];
      }
      combinedData[key].push(entry);
    });
    return combinedData;
  };

  // Get check-in and check-out times from combined data
  const getInOutTimes = (combinedData) => {
    const inOutTimes = [];
    for (const key in combinedData) {
      const entries = combinedData[key];
      const sortedEntries = entries.sort(
        (a, b) => new Date(a.punch_time) - new Date(b.punch_time)
      );
      const checkIn = formatDate(sortedEntries[0].punch_time).time;
      const checkOut =
        sortedEntries.length > 1
          ? formatDate(sortedEntries[sortedEntries.length - 1].punch_time).time
          : "-";
      const studentName = entries[0].student_name; // Include student name
      const timeSlot = entries[0].timeslot; // Include time slot
      inOutTimes.push({
        emp_code: entries[0].emp_code,
        date: entries[0].punch_time.split("T")[0],
        checkIn,
        checkOut,
        student_name: studentName,
        time_slot: timeSlot,
      });
    }
    return inOutTimes;
  };

  const combinedData = preprocessAttendanceData();
  const inOutTimes = getInOutTimes(combinedData);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // Handle search input changes for employee code
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle search input changes for date
  const handleDateChange = (event) => {
    setDateQuery(event.target.value);
  };

  // Filter attendance data based on the search query and date
  const filteredInOutTimes = inOutTimes.filter(
    (entry) =>
      entry.emp_code.toLowerCase().includes(searchQuery.toLowerCase()) &&
      entry.date.includes(dateQuery)
  );

  return (
    <div className="background">
      <Header title="Attendance Table" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBarEliteRole />
        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  <TextField
                    style={{ width: "224px" }}
                    label="Search by Employee Code"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <TextField
                    style={{ width: "224px" }}
                    label="Search by Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={dateQuery}
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </Stack>
            </div>

            <br />
            <br />
            <br />
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>emp code</StyledTableCell>
                    <StyledTableCell>emp name</StyledTableCell>
                    <StyledTableCell>Time Slot</StyledTableCell>
                    <StyledTableCell>date</StyledTableCell>
                    <StyledTableCell>check-in</StyledTableCell>
                    <StyledTableCell>check-out</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredInOutTimes.map((entry, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{entry.emp_code}</StyledTableCell>
                      <StyledTableCell>{entry.student_name}</StyledTableCell>
                      <StyledTableCell>{entry.time_slot}</StyledTableCell>
                      <StyledTableCell>{entry.date}</StyledTableCell>
                      <StyledTableCell>{entry.checkIn}</StyledTableCell>
                      <StyledTableCell>{entry.checkOut}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            style={{ backgroundColor: "#306ef3" }}
          >
            Scroll To Top
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default AttendanceRole;
