import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import SideBarEliteRole from "../Ellite_Role/SideBarEliteRole";
import { useLocation, useParams } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UpdateStudentRole = () => {
  const { ID } = useParams(); // Assuming you're using react-router for routing and have the ID in the

  const [weekDays, setWeekDays] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [formData, setFormData] = useState({
    EMP_CODE: "",
    NAME: "",
    SURNAME: "",
    SEX: "",
    AGE: "",
    PHONE_NUMBER: "",
    GUARDIAN_NAME: "",
    GUARDIAN_PHONE_NUMBER: "",
    RELATIONSHIP: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    STATUS: "",
    PACKAGE_NAME: "",
    SPORT_TYPE: "",
    DURATION: "",
    TIME_SLOT: "",
    START_DATE: "",
    END_DATE: "",
    PRICE: "",
    PACKAGE_ID: "",
    RENEWED: "",
    DISCOUNT: "",
    CATEGORY: "",
    WEEK_DAYS: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${API_BASE_URL}/student/${ID}`, formData)
      .then((response) => {
        console.log(response.data);
        window.alert("User updated successfully");
      })
      .catch((error) => {
        console.error("Error updating package:", error);
        window.alert("Error updating package");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/fetch/${ID}`);
        const fetchedData = response.data;
        // Ensure date fields are correctly formatted
        if (fetchedData.START_DATE) {
          fetchedData.START_DATE = new Date(fetchedData.START_DATE)
            .toISOString()
            .split("T")[0];
        }
        if (fetchedData.END_DATE) {
          fetchedData.END_DATE = new Date(fetchedData.END_DATE)
            .toISOString()
            .split("T")[0];
        }
        setFormData(fetchedData);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [ID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "START_DATE" || name === "DURATION") {
      const startDate =
        name === "START_DATE" ? new Date(value) : new Date(formData.START_DATE);
      const duration =
        name === "DURATION" ? parseInt(value) : parseInt(formData.DURATION);

      if (!isNaN(startDate.getTime()) && !isNaN(duration)) {
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + duration);
        setFormData((prevFormData) => ({
          ...prevFormData,
          END_DATE: endDate.toISOString().split("T")[0],
        }));
      }
    }
  };

  // Modify handleChangePackage to update PACKAGE_NAME and send data to server immediately
  const handleChangePackage = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      PACKAGE_NAME: newValue,
    }));

    // Send data to server immediately
    sendDataToServer();
  };

  // Modify handleChangeCategory to update CATEGORY
  const handleChangeCategory = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      CATEGORY: newValue,
    }));
    sendDataToServer();
  };

  // Modify handleChangeWeekDay to update DAYS
  const handleChangeWeekDay = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      WEEK_DAYS: newValue,
    }));
    sendDataToServer();
  };

  const sendDataToServer = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/price`, formData);
      console.log(response.data);
      const { price, PACKAGE_ID } = response.data;

      // Update formData with the received price and id
      setFormData((prevFormData) => ({
        ...prevFormData,
        PRICE: price,
        PACKAGE_ID: PACKAGE_ID,
      }));
    } catch (error) {
      console.error("Error inserting data:", error);
      // window.alert("Failed to insert the data");
    }
  };

  // Fetch data from Express.js backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/products`)
      .then((response) => {
        const { package_name, package_date, category_data } = response.data;

        const batchNames = package_date.map((entry) => entry.PACKAGE_BATCH);
        const packageName = package_name.map((entry) => entry.PACKAGE_NAME);
        const categoryName = category_data.map((entry) => entry.CATEGORY);

        setWeekDays(batchNames);
        setPackageName(packageName);
        setCategoryData(categoryName);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const TIME_SLOT = [
    "06:00 AM - 07:00 AM",
    "07:00 AM - 08:00 AM",
    "08:00 AM - 09:00 AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 01:00 PM",
    "01:00 PM - 02:00 PM",
    "03:00 PM - 04:00 PM",
    "04:00 PM - 05:00 PM",
    "05:00 PM - 06:00 PM",
    "06:00 PM - 07:00 PM",
  ];

  const title = "Student Details";

  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBarEliteRole />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[]} // Set options to avoid the undefined length error
                    freeSolo
                    name="EMP_CODE"
                    value={formData.EMP_CODE}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, EMP_CODE: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee Code"
                        variant="outlined"
                        name="EMP_CODE"
                        value={formData.EMP_CODE}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="NAME"
                    variant="outlined"
                    value={formData.NAME}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Sex</InputLabel>
                    <Select
                      label="Sex"
                      name="SEX"
                      value={formData.SEX}
                      onChange={handleChange}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Age"
                    name="AGE"
                    type="number"
                    value={formData.AGE}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    type="number"
                    value={formData.PHONE_NUMBER}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian Name"
                    name="GUARDIAN_NAME"
                    value={formData.GUARDIAN_NAME}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian PhNo."
                    name="GUARDIAN_PHONE_NUMBER"
                    type="number"
                    value={formData.GUARDIAN_PHONE_NUMBER}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Relation</InputLabel>
                    <Select
                      label="Relation"
                      name="RELATIONSHIP"
                      value={formData.RELATIONSHIP}
                      onChange={handleChange}
                    >
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    value={formData.ADDRESS}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    value={formData.CITY}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    value={formData.STATE}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      value={formData.STATUS}
                      onChange={handleChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active </MenuItem>
                      <MenuItem value="Pause">Pause</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* ////////////////////////////////////////////////////////////////// */}
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Class Information
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={weekDays}
                    name="WEEK_DAYS"
                    // sx={{ width: }}
                    value={formData.WEEK_DAYS}
                    onChange={handleChangeWeekDay}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Week Days"
                        value={formData.WEEK_DAYS}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="category"
                    name="CATEGORY"
                    options={categoryData}
                    value={formData.CATEGORY}
                    onChange={(event, newValue) => {
                      handleChangeCategory(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="package-name"
                    options={packageName}
                    value={formData.PACKAGE_NAME}
                    onChange={(event, newValue) => {
                      handleChangePackage(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Package Name" />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Sport Type"
                    name="SPORT_TYPE"
                    variant="outlined"
                    value={formData.SPORT_TYPE}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="PRICE"
                    variant="outlined"
                    value={formData.PRICE}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Renewed</InputLabel>
                    <Select
                      label="Renewed"
                      name="RENEWED"
                      value={formData.RENEWED}
                      onChange={handleChange}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Time Slot</InputLabel>
                    <Select
                      label="Time Slot"
                      name="TIME_SLOT"
                      value={formData.TIME_SLOT}
                      onChange={handleChange}
                    >
                      {TIME_SLOT.map((slot, index) => (
                        <MenuItem key={index} value={slot}>
                          {slot}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Time Slot"
                    name="TIME_SLOT"
                    variant="outlined"
                    value={formData.TIME_SLOT}
                    onChange={handleChange}
                  />
                </Grid> */}

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Duration"
                    name="DURATION"
                    variant="outlined"
                    value={formData.DURATION}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Other</InputLabel>
                    <Select
                      label="Other"
                      name="DISCOUNT"
                      value={formData.DISCOUNT}
                      onChange={handleChange}
                    >
                      <MenuItem value="DISCOUNT">DISCOUNT</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.START_DATE}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="End Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.END_DATE}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "20% !important",
                      backgroundColor: " #306ef3!important",
                    }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default UpdateStudentRole;
