import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { AuthContext } from "./AuthProvider";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import "../ComponentsCSS/Header.css";
import { useNavigate } from "react-router-dom";
import logo from "./elite1.png";
import axios from "axios";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import NotificationsList from "./NotificationsList"; // Import the new NotificationsList component

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const HeaderAttendance = ({ title, invalidCheckIns }) => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElN, setAnchorElN] = React.useState(null);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [count, setCount] = React.useState(1);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

const handleNotification = (event) => {
  setAnchorElN((prevAnchorElN) => (prevAnchorElN ? null : event.currentTarget));
};


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseN = () => {
    setAnchorElN(null);
  };

  const handleLogOut = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/logout`);
      if (response.status === 200) {
        logout();
        navigate("/");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#eae7eb",
          width: "100%",
          position: "relative",
        }}
      >
        <Toolbar>
          <Box
            component="img"
            className="logoHead"
            alt="Your logo."
            src={logo}
          />

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 0,
              position: "relative",
              color: "rgb(128 127 127 / 95%)",
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          {auth && (
            <div>
              <Badge
                color="error"
                badgeContent="1"
                sx={{
                  position: "absolute",
                  right: "50px",
                  height: "30px",
                  top: "12px",
                }}
                onClick={handleNotification}
                
              >
                <NotificationsIcon style={{ color: "black", height: "30px" }} />
              </Badge>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{
                  position: "absolute",
                  right: "0px",
                  height: "30px",
                  top: "10px",
                }}
              >
                <AccountCircle style={{ color: "black", height: "30px" }} />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
              </Menu>

              <NotificationsList
                anchorElN={anchorElN}
                handleCloseN={handleCloseN}
                notifications={invalidCheckIns}
              />
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HeaderAttendance;
