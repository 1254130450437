import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";

import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function UpdateEmployee(props) {
  const { ID } = useParams(); // Destructure id from useParams

  const [formData, setFormData] = useState({
    USERNAME: "",
    PASSWORD: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    PHONE_NUMBER: "",
    WHATSAPP_PHONE_NUMBER: "",
    EMAIL_ID: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    START_DATE: "",
    END_DATE: "",
    EMPLOYEE_TYPE: "",
    STATUS: "",
    PAY_TYPE: "",
    PAY: "",
  });

  useEffect(() => {
    // Fetch existing data based on ID and set the form data
    axios
      .get(`${API_BASE_URL}/user/${ID}`)
      .then((response) => {
        const data = response.data;
        setFormData({
          ...data,
          START_DATE: data.START_DATE ? data.START_DATE.split("T")[0] : "",
          END_DATE: data.END_DATE ? data.END_DATE.split("T")[0] : "",
        });
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [ID]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${API_BASE_URL}/user/${ID}`, formData)
      .then((response) => {
        console.log(response.data);
        window.alert("Updated successfully");
      })
      .catch((error) => {
        console.error("Error updating:", error);
        window.alert("Error updating");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const title = "Employee Update";

  console.log(formData.date);

  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form onSubmit={handleSubmit} id="form">
              <br></br>
              <Grid container spacing={3} className="grid2">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Employee Update
                  </Typography>
                </Grid>

                {/* <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="ID"
                    name="ID"
                    variant="outlined"
                    value={formData.ID}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid> */}

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="UserName"
                    name="USERNAME"
                    variant="outlined"
                    value={formData.USERNAME}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="PASSWORD"
                    variant="outlined"
                    value={formData.PASSWORD}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Frist Name"
                    name="FIRST_NAME"
                    variant="outlined"
                    value={formData.FIRST_NAME}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="LastName"
                    name="LAST_NAME"
                    variant="outlined"
                    value={formData.LAST_NAME}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    variant="outlined"
                    value={formData.PHONE_NUMBER}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Whatsapp Number"
                    name="WHATSAPP_PHONE_NUMBER"
                    variant="outlined"
                    value={formData.WHATSAPP_PHONE_NUMBER}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="EMAIL_ID"
                    variant="outlined"
                    value={formData.EMAIL_ID}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    variant="outlined"
                    value={formData.ADDRESS}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    variant="outlined"
                    value={formData.CITY}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    variant="outlined"
                    value={formData.STATE}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Join Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.START_DATE}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Exit Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.END_DATE} // Bind the END_DATE state variable
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    name="EMPLOYEE_TYPE"
                  >
                    <InputLabel>Employee Type</InputLabel>
                    <Select
                      label="Employee_Type"
                      name="EMPLOYEE_TYPE"
                      value={formData.EMPLOYEE_TYPE}
                      onChange={handleChange}
                    >
                      <MenuItem value="Admin">Admin </MenuItem>
                      <MenuItem value="Supervisor">Supervisor</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      value={formData.STATUS}
                      onChange={handleChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="InActive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Pay Type"
                    name="PAY_TYPE"
                    variant="outlined"
                    value={formData.PAY_TYPE}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Pay"
                    name="PAY"
                    type="number"
                    variant="outlined"
                    value={formData.PAY}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <br />

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: "20%", backgroundColor: "#306ef3" }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default UpdateEmployee;
