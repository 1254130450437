import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Login from "./Components/Login";
import DashBoard from "./Pages/Dashboard";
import ManageMembers from "./Pages/ManageMembers";
// import Report from "./Pages/Report";
import Attendance from "./Pages/Attendance";
import Users from "./Pages/Users";
import ManageEmployees from "./Pages/ManageEmployees";
import UpdateEmployee from "./Pages/UpdateEmployee";
import StudentData from "./Pages/StudentData";
import Packages from "./Pages/Packages";
import ManagePackage from "./Pages/ManagePackage";
import UpdateStudent from "./Pages/UpdateStudent"; 
import IntroScreen from "./Components/IntroScreen";
import IntroScreen1 from "./Components/IntroScreen1";
import Attendance1 from "./SecondPage/Attendance1";
import ManageMembers1 from "./SecondPage/ManageMembers1";
import ManageEmployees1 from "./SecondPage/ManageEmployees1";
import ManagePackage1 from "./SecondPage/ManagePackage1";
import Packages1 from "./SecondPage/Packages1";
// import Report1 from "./SecondPage/Report1";
import Dashboard1 from "./SecondPage/Dashboard1";
import StudentData1 from "./SecondPage/StudentData1";
import StudentUpdate1 from "./SecondPage/StudentUpdate1";
import UpdateEmployee1 from "./SecondPage/UpdateEmployee1";
import Users1 from "./SecondPage/Users1";
import UpdatePackage from "./Pages/UpdatePackage";
import DashBoardEliteRole from "./Ellite_Role/DashboardEliteRole";
import StudentEliteRole from "./Ellite_Role/StudentEliteRole";
import ManageEliteRole from "./Ellite_Role/ManageEliteRole";
import UpdateStudentRole from "./Ellite_Role/UpdateStudentRole";
import AttendanceRole from "./Ellite_Role/AttendanceRole";
import PackageRole from "./Ellite_Role/PackageRole";
import ManagePackageRole from "./Ellite_Role/ManagePackageRole";
import UpdatePackageRole from "./Ellite_Role/UpdatePackageRole";
import Header1 from "./Components/Header1";
import UpdatePackage1 from "./SecondPage/UpdatePackage1";
import PrivateRoute from "./Components/PrivateRoute";
import { AuthProvider } from "./Components/AuthProvider";
import { useState, useEffect } from "react";
// import { StateProvider } from "./Components/Context/StateContext";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 860,
      lg: 1050,
      xl: 1400,
    },
  },
});

function App() {
  const [attendanceCount, setAttendanceCount] = useState(0);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/StudentData"
            element={
              <PrivateRoute>
                <StudentData />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManageMembers"
            element={
              <PrivateRoute>
                <ManageMembers />
              </PrivateRoute>
            }
          />

          <Route
            path="/ManageMembers/:status"
            element={
              <PrivateRoute>
                <ManageMembers />
              </PrivateRoute>
            }
          />

          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                <DashBoard attendanceCount={attendanceCount} />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/Report"
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/Attendance"
            element={
              <PrivateRoute>
                <Attendance setAttendanceCount={setAttendanceCount} />
              </PrivateRoute>
            }
          />
          <Route
            path="/Users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManageEmployees"
            element={
              <PrivateRoute>
                <ManageEmployees />
              </PrivateRoute>
            }
          />
          <Route
            path="/UpdateEmployee/:ID"
            element={
              <PrivateRoute>
                <UpdateEmployee />
              </PrivateRoute>
            }
          />
          <Route
            path="/UpdatePackage/:ID"
            element={
              <PrivateRoute>
                <UpdatePackage />
              </PrivateRoute>
            }
          />
          <Route
            path="/Packages"
            element={
              <PrivateRoute>
                <Packages />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManagePackage"
            element={
              <PrivateRoute>
                <ManagePackage />
              </PrivateRoute>
            }
          />
          <Route
            path="/UpdateStudent/:ID"
            element={
              <PrivateRoute>
                <UpdateStudent />
              </PrivateRoute>
            }
          />
          <Route
            path="/IntroScreen"
            element={
              <PrivateRoute>
                <IntroScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/DashBoardEliteRole"
            element={
              <PrivateRoute>
                <DashBoardEliteRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/StudentEliteRole"
            element={
              <PrivateRoute>
                <StudentEliteRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManageEliteRole"
            element={
              <PrivateRoute>
                <ManageEliteRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/UpdateStudentRole/:ID"
            element={
              <PrivateRoute>
                <UpdateStudentRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/AttendanceRole"
            element={
              <PrivateRoute>
                <AttendanceRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/PackageRole"
            element={
              <PrivateRoute>
                <PackageRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManagePackageRole"
            element={
              <PrivateRoute>
                <ManagePackageRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/UpdatePackageRole/:ID"
            element={
              <PrivateRoute>
                <UpdatePackageRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/IntroScreen1"
            element={
              <PrivateRoute>
                <IntroScreen1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/Header1"
            element={
              <PrivateRoute>
                <Header1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/Dashboard1"
            element={
              <PrivateRoute>
                <Dashboard1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/Attendance1"
            element={
              <PrivateRoute>
                <Attendance1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManageMembers1"
            element={
              <PrivateRoute>
                <ManageMembers1 />
              </PrivateRoute>
            }
          />

          <Route
            path="/ManageMembers1/:status"
            element={
              <PrivateRoute>
                <ManageMembers1 />
              </PrivateRoute>
            }
          />

          <Route
            path="/ManageEmployees1"
            element={
              <PrivateRoute>
                <ManageEmployees1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/ManagePackage1"
            element={
              <PrivateRoute>
                <ManagePackage1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/Packages1"
            element={
              <PrivateRoute>
                <Packages1 />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/Report1"
            element={
              <PrivateRoute>
                <Report1 />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/StudentData1"
            element={
              <PrivateRoute>
                <StudentData1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/StudentUpdate1/:ID"
            element={
              <PrivateRoute>
                <StudentUpdate1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/UpdateEmployee1/:ID"
            element={
              <PrivateRoute>
                <UpdateEmployee1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/UpdatePackage1/:ID"
            element={
              <PrivateRoute>
                <UpdatePackage1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/Users1"
            element={
              <PrivateRoute>
                <Users1 />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
