import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
} from "react-pro-sidebar";
import {
  FaAlignJustify,
  FaUsers,
  FaUserTie,
  FaIndustry,
  FaToggleOn,
  FaToggleOff,
} from "react-icons/fa";
import { FaChartSimple } from "react-icons/fa6";
import { BsDatabaseFillAdd } from "react-icons/bs";
import { AiOutlineAreaChart, AiOutlineAudit } from "react-icons/ai";
import { IoHome } from "react-icons/io5";

import { MdAccountCircle } from "react-icons/md";
import "../ComponentsCSS/Sidebar.css";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import elite from "./elite1.png";
import skyline from "./skyline.png";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";

const SideBar = ({ selectedLogo }) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [collapse, setCollapse] = useState(false);
  const [display, setDisplay] = useState("");
  const [breakPoint, setBreakPoint] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {


    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserData(userData);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setWidth(windowWidth);
      if (windowWidth < 600) {
        setCollapse(true);
        setDisplay("");
        setBreakPoint(true);
      } else {
        setCollapse(false);
        setDisplay("display");
        setBreakPoint(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCollapse = () => {
    setCollapse(!collapse);
    setBreakPoint(!collapse);
  };

  // Map selectedSport to the respective image file
  const getImageSource = () => {
    if (selectedLogo === "swimming") {
      return elite; // Replace with the actual path to the elite logo image
    } else if (selectedLogo === "badminton") {
      return skyline; // Replace with the actual path to the skyline logo image
    } else {
      return "default_image_path"; // If no selectedLogo matches, provide a default image path
    }
  };

  return (
    <div className="sidebar">
      <Sidebar
        collapsed={collapse}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "rgb(0, 79, 120)",
            paddingTop: "40px",
            position: "relative",
            bottom: "65px",
            height: "100vh",
          },
        }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, disabled }) => ({
              color: disabled ? "white" : "white",
              "&:hover": {
                backgroundColor: "#8a8dc5",
              },
            }),
          }}
        >
          {!collapse && (
            <>
              <Box
                component="img"
                className="logo"
                alt="Your logo."
                src={elite}
              />
            </>
          )}

          <h5 className="Admin">Temperature Controlled</h5>

          <Link to="/IntroScreen">
            <MenuItem icon={<IoHome />}>Home</MenuItem>
          </Link>

          {/* ............ Start of the sidebar fields........................ */}
          <Link to="/Dashboard">
            <MenuItem icon={<FaChartSimple />}> Dashboard </MenuItem>
          </Link>

          {/* <Link to="/dsr">
              <MenuItem icon={<BsDatabaseFillAdd />}>  Members Data</MenuItem>
            </Link> */}

          <SubMenu
            icon={<FaUsers />}
            className="Sub-Menu"
            label="Students Data"
          >
            <Link to="/StudentData">
              <MenuItem icon={<FaUserTie />}> Add Students </MenuItem>
            </Link>

            <Link to="/ManageMembers">
              <MenuItem icon={<BsDatabaseFillAdd />}>
                {" "}
                Manage Students{" "}
              </MenuItem>
            </Link>
          </SubMenu>

          <Link to="/Attendance">
            <MenuItem icon={<AiOutlineAudit />}> Attendance </MenuItem>
          </Link>
          {/* <SubMenu  className="Sub-Menu"  icon={<AiOutlineAreaChart />}  label="Reports"
          >
            <Link to="/dsrTable">
              <MenuItem icon={<BsDatabaseFillAdd />}> Manage DSR </MenuItem>
            </Link>
          </SubMenu> */}

          <SubMenu icon={<FaUsers />} className="Sub-Menu" label="Employees">
            <Link to="/Users">
              <MenuItem icon={<MdAccountCircle />}> Add Employees </MenuItem>
            </Link>

            <Link to="/ManageEmployees">
              <MenuItem icon={<BsDatabaseFillAdd />}>
                {" "}
                Manage Employees{" "}
              </MenuItem>
            </Link>
          </SubMenu>

          <SubMenu
            icon={<InventoryOutlinedIcon sx={{ fontSize: 20 }} />}
            className="Sub-Menu"
            label="Packages"
            
          >
            <Link to="/Packages">
              <MenuItem icon={<MdAccountCircle />}> Add Packages </MenuItem>
            </Link>

            <Link to="/ManagePackage">
              <MenuItem icon={<BsDatabaseFillAdd />}>
                {" "}
                Manage Packages{" "}
              </MenuItem>
            </Link>
          </SubMenu>
        </Menu>
      </Sidebar>

      {/* <div
        className={`toggle-button ${display ? "" : "hidden"}`}
        onClick={toggleCollapse}
      >
        {collapse ? (
          <FaToggleOn className="toggle-icon" />
        ) : (
          <FaToggleOff className="toggle-icon" />
        )}
      </div> */}
      <div
        className={`toggle-button ${display ? "" : "hidden"}`}
        onClick={toggleCollapse}
      >
        {collapse ? (
          <FaToggleOn className="toggle-icon" />
        ) : (
          <FaToggleOff className="toggle-icon" />
        )}
      </div>
    </div>
  );
};

export default SideBar;
