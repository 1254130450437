// import React, { useEffect, useState } from "react";
// import logo from "./elite1.png";
// import logo2 from "./skyline.png";
// import '../ComponentsCSS/IntoScreen.css'
// import { Button } from "@mui/material";
// import { Link } from "react-router-dom";
// import SideBar from "./SideBar";

// function IntroScreen() {

//   const [selectedLogo, setSelectedLogo] = useState("");

// const handleLogoSelect = (sport) => {
//   console.log("Selected sport:", sport); // Check if the function is called with the correct sport
//   setSelectedLogo(sport);
//   console.log(selectedLogo +"raw");
// };

//   console.log(selectedLogo +"raw");

//   useEffect(() => {
//     console.log(selectedLogo + " raw");
//   }, [selectedLogo]);

//   return (
//     <main className="main-container1">

//       <div style={{ display: "flex", flexDirection: "row", backgroundColor:"#eae7eb"}}>
//         <div className="main-cards1">

//         <h1 id="banner_name">Welcome To Satya Sports Center</h1>
//           <div className="row-cards1">

//         <Link to="/Dashboard">

//             <Button onClick={() => handleLogoSelect("logo")} >
//                 <div className="card1">
//                   <div className="card-inner1">
//                     <img id="logo_renew" src={logo} alt="elite_logo" />
//                   </div>
//                 </div>
//             </Button>

//         </Link>

//         <Link to="/Dashboard">
//           <Button onClick={() => handleLogoSelect("logo2")} >
//               <div className="card1">
//                 <div className="card-inner1">
//                   <img id="logo1" src={logo2} alt="elite_logo" />
//                 </div>
//               </div>
//           </Button>
//         </Link>

//           </div>
//         </div>

//       </div>

//         {/* Pass selectedLogo as prop to SideBar */}
//         {selectedLogo && <SideBar selectedLogo={selectedLogo} />}
//     </main>
//   );
// }

// export default IntroScreen;

import React, { useEffect, useState } from "react";
import logo from "./elite1.png";
import logo2 from "./skyline.png";
import "../ComponentsCSS/IntoScreen.css";
import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";
import DashBoard from "../Pages/Dashboard";

function IntroScreen() {
  const location = useLocation();

  const [selectedLogo, setSelectedLogo] = useState("");

  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("useEffect triggered. selectedLogo:", selectedLogo);
  // }, [selectedLogo]);

  const handleLogoSelect = (sport) => {
    setSelectedLogo(sport);
    const data = "swimming";
    navigate("/DashBoard", {
      state: {
        data,
      },
    });
  };

  const handleLogoSelects = (sport) => {
    setSelectedLogo(sport);
    navigate("/DashBoard1");
  };

  // console.log("selectedLogo:", selectedLogo);

  return (
    <main className="main-container1">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <div className="main-cards1">
          <h1 id="banner_name">Welcome To Satya Sports Center</h1>
          <div className="row-cards1">
            <Button onClick={() => handleLogoSelect("swimming")}>
              <div className="card1 card-1">
                <div className="card-inner1">
                  <img id="logo_renew" src={logo} alt="elite_logo" />
                </div>
              </div>
              {/* {selectedLogo && <DashBoard selectedLogo="swimming" />} */}
              {selectedLogo && <SideBar selectedLogo={selectedLogo} />}
            </Button>

            <Button onClick={() => handleLogoSelects("badminton")}>
              <div className="card1 card-2">
                <div className="card-inner1">
                  <img id="logo1" src={logo2} alt="elite_logo" />
                </div>
              </div>
              {/* {selectedLogo && <DashBoard selectedLogo="badminton" />} */}
              {selectedLogo && <SideBar selectedLogo={selectedLogo} />}
            </Button>
          </div>
        </div>
      </div>
      {selectedLogo && <SideBar selectedLogo={selectedLogo} />}
      {/* {selectedLogo && <DashBoard selectedLogo={selectedLogo} />} */}
    </main>
  );
}

export default IntroScreen;
