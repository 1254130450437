import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
// import Autocomplete from "@mui/material/Autocomplete/";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const StudentData = () => {
  const location = useLocation();
  const selectedLogo = new URLSearchParams(location.search).get("selectedLogo");
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = useState([]);
  const [empCodes, setEmpCodes] = useState([]);
  const [packageIds, setPackageIds] = useState([]);
  const [package_Id, setpackage_Id] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  // Define a state to track if the alert has been shown
  const [alertShown, setAlertShown] = useState(false);
  const [empCode, setEmpCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [GphoneNumber, setGPhoneNumber] = useState("");
  const [GphoneNumberError, setGPhoneNumberError] = useState(false);
  const [AadharNumber, setAadharNumber] = useState("");
  const [AadharNumberError, setAadharNumberError] = useState(false);

  // Fetch data from Express.js backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/products`)
      .then((response) => {
        const { package_name, package_date, category_data } = response.data;

        const batchNames = package_date.map((entry) => entry.PACKAGE_BATCH);
        const packageName = package_name.map((entry) => entry.PACKAGE_NAME);
        const categoryName = category_data.map((entry) => entry.CATEGORY);

        setWeekDays(batchNames);
        setPackageName(packageName);
        setCategoryData(categoryName);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Fetch employee codes from Express.js backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user_details`)
      .then((response) => {
        setEmpCodes(response.data.map((entry) => entry.emp_code)); // Extracting emp_code from each entry
      })
      .catch((error) => {
        console.error("Error fetching employee codes:", error);
      });
  }, []); // Empty dependency array ensures the effect runs only once

  console.log(empCodes);

  const [formData, setFormData] = useState({
    EMP_CODE: "",
    NAME: "",
    SURNAME: "",
    SEX: "",
    AGE: "",
    PHONE_NUMBER: "",
    GUARDIAN_NAME: "",
    GUARDIAN_PHONE_NUMBER: "",
    RELATIONSHIP: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    STATUS: "",
    PACKAGE_NAME: "",
    SPORT_TYPE: "Swimming",
    DURATION: "",
    TIME_SLOT: "",
    START_DATE: "",
    END_DATE: "",
    COMMENTS: "",
    ENROLLMENT: "",
    PRICE: "",
    WEEK_DAYS: "",
    CATEGORY: "",
    PACKAGE_ID: "",
    RENEWED: "",
    DISCOUNT: "",
    TOTAL_AMOUNT: "",
    PAUSE_START_DATE: "",
    PAUSE_END_DATE: "",
    AADHAR_NUMBER: "",
  });

  // to insert the data from frontend to backend
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission action
    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true);
    console.log("Form submitted, preventing default action");

    try {
      console.log("Form Data on Submit: ", formData);
      const response = await axios.post(`${API_BASE_URL}/student`, formData);
      console.log("Response from student API:", response.data);
      window.alert("Successfully inserted the student data");

      const downloadPdf = async (endpoint, fileName) => {
        try {
          const res = await axios.post(
            `${API_BASE_URL}/${endpoint}`,
            { formData },
            { responseType: "blob" }
          );
          console.log(`PDF download request successful for ${fileName}`);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          console.log(`${fileName} downloaded successfully`);
        } catch (error) {
          console.error(`Error downloading ${fileName}:`, error);
        }
      };

      await downloadPdf("pdfstudent", `${formData.NAME} Bill.pdf`);
      await downloadPdf(
        "pdfMembershipStudent",
        `${formData.NAME} MembershipDetails.pdf`
      );
      window.location.reload(); // Reload the page after successful submission and PDF download
    } catch (error) {
      console.error("Error inserting data:", error);

      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "emp_code already exists"
      ) {
        window.alert("Failed to insert data: emp_code already exists");
      } else {
        window.alert("Failed to insert the data");
      }

      setIsSubmitting(false); // Re-enable the submit button if an error occurs
    }
  };

  // to perform the discount operation and take the input from the user and store in the variables

  // Update handleChange function to handle changes for Employee Code (EMPCODE)
  const handleChange = (e) => {
    const { name, value } = e.target || e;

    setFormData({
      ...formData,
      [name]: value, // Parse value to float
    });

    if (name === "EMP_CODE") {
      setEmpCode(value);
    }

    if (name === "PHONE_NUMBER") {
      setPhoneNumber(value);

      if (value.length > 10 || value.length < 10) {
        setPhoneNumberError(true);
      } else {
        setPhoneNumberError(false);
      }
    }

    if (name === "AADHAR_NUMBER") {
      setAadharNumber(value);

      if (value.length > 12 || (value.length < 12 && value.length > 0)) {
        setAadharNumberError(true);
      } else {
        setAadharNumberError(false);
      }
    }

    if (name === "GUARDIAN_PHONE_NUMBER") {
      setGPhoneNumber(value);

      if (value.length > 10 || (value.length < 10 && value.length > 0)) {
        setGPhoneNumberError(true); // Set error state when length is greater than 10 or between 1 and 9
      } else {
        setGPhoneNumberError(false); // Clear error state when length is exactly 10 or 0
      }
    }

    // Calculate total amount
    if (name === "START_DATE" || name === "DURATION") {
      const startDate =
        name === "START_DATE" ? new Date(value) : new Date(formData.START_DATE);
      const duration =
        name === "DURATION" ? parseInt(value) : parseInt(formData.DURATION);

      if (!isNaN(startDate.getTime()) && !isNaN(duration)) {
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + duration);

        // Subtract one day from the end date
        endDate.setDate(endDate.getDate() - 1);

        setFormData((prevFormData) => ({
          ...prevFormData,
          END_DATE: endDate.toISOString().split("T")[0],
        }));
      }
    }

    if (name === "DISCOUNT" || name === "PRICE") {
      const price = parseFloat(name === "PRICE" ? value : formData.PRICE);
      const discount = parseFloat(
        name === "DISCOUNT" ? value : formData.DISCOUNT
      );
      if (!isNaN(price) && !isNaN(discount)) {
        const totalAmount = price - discount;
        setFormData((prevFormData) => ({
          ...prevFormData,
          TOTAL_AMOUNT: totalAmount.toFixed(2),
        }));
      }
    }

    if (name === "STATUS") {
      const showDates = value === "Pause";
      setFormData((prevFormData) => ({
        ...prevFormData,
        STATUS: value,
      }));
      setShowDates(showDates);
    }
  };

  // start the auto fill of variables by selecting the package type which is from product table

  useEffect(() => {
    fetchOptions();
  }, []); // Fetch options on component mount

  const fetchOptions = () => {
    axios
      .get(`${API_BASE_URL}/api/options`)
      .then((response) => {
        console.log("Response:", response.data); // Log the response data
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error); // Log any errors
      });
  };

  // Modify handleChangePackage to update PACKAGE_NAME and send data to server immediately
  const handleChangePackage = async (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      PACKAGE_NAME: newValue,
    }));

    // Fetch weekdays based on selected category and package name
    try {
      const response = await axios.post(`${API_BASE_URL}/api/weekdays`, {
        category: formData.CATEGORY,
        packageName: newValue,
      });

      const { data } = response;

      console.log(data);
      setWeekDays(data);
    } catch (error) {
      console.error("Error fetching weekdays:", error);
    }
    sendDataToServer();
  };

  // Modify handleChangeCategory to update CATEGORY
  const handleChangeCategory = async (event, newValue) => {
    try {
      setFormData((prevData) => ({
        ...prevData,
        CATEGORY: newValue,
      }));

      const response = await axios.post(`${API_BASE_URL}/api/package-names`, {
        category: newValue,
      });

      const { data } = response;
      setPackageName(data);
    } catch (error) {
      console.error("Error fetching package names:", error);
    }

    sendDataToServer();
  };

  // Modify handleChangeWeekDay to update DAYS
  const handleChangeWeekDay = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      WEEK_DAYS: newValue,
    }));
    sendDataToServer();
  };

  // Function to send form data to the server
  const sendDataToServer = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/price`, formData);
      console.log(response.data);
      const { price, PACKAGE_ID, status, DURATION } = response.data;

      // Check if STATUS is 'In Active' and show alert message

      setFormData((prevFormData) => ({
        ...prevFormData,
        PRICE: price,
        PACKAGE_ID: PACKAGE_ID,
        STATUS1: status,
        DURATION: DURATION,
      }));
    } catch (error) {
      console.error("Error retrieving data:", error);
      // window.alert("Failed to retrieve the data");
    }
  };

  const TIME_SLOT = [
    "06 - 07 AM",
    "07 - 08 AM",
    "08 - 09 AM",
    "09 - 10 AM",
    "10 - 11 AM",
    "11 - 12 AM",
    "12 - 01 PM",
    "01 - 02 PM",
    "02 - 03 PM",
    "03 - 04 PM",
    "04 - 05 PM",
    "05 - 06 PM",
    "06 - 07 PM",
    "07 - 08 PM",
    "08 - 09 PM",
    "09 - 10 PM",
  ];

  // Effect to trigger form submission when all fields are selected
  useEffect(() => {
    if (formData.PACKAGE_NAME && formData.WEEK_DAYS && formData.CATEGORY) {
      sendDataToServer();
    }
  }, [formData]);
  console.log(formData);

  const [showDates, setShowDates] = useState(false); // State variable to control the display of Start Date and End Date fields
  // end of the fetching the data from the product table and display it.

  // Fetch the last emp_code from the backend
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/fetchEmpCode`)
      .then((response) => {
        console.log("Response data:", response.data);

        const lastEmpCodeString = response.data[0].emp_code;

        // Parse the emp_code while preserving leading zeros
        const empCodeNumber = parseInt(lastEmpCodeString, 10);
        console.log(empCodeNumber);
        if (isNaN(empCodeNumber)) {
          console.error("Invalid emp_code format:", lastEmpCodeString);
          return;
        }

        const nextEmpCode = empCodeNumber + 1;
        const nextEmpCodeString = lastEmpCodeString.replace(/\d+$/, (num) =>
          String(nextEmpCode).padStart(num.length, "0")
        );

        console.log(nextEmpCodeString);

        setEmpCode(nextEmpCodeString);

        setFormData((prevFormData) => ({
          ...prevFormData,
          EMP_CODE: nextEmpCodeString,
        }));
      })
      .catch((error) => {
        console.error("Error fetching employee codes:", error);
      });
  }, []); // Empty dependency array ensures the effect runs only once

  // Function to handle change in the current Employee Code field
  const handleChangeCurrentEmpCode = (event) => {
    const newEmpCode = event.target.value;
    setEmpCode(newEmpCode);
    setFormData((prevFormData) => ({
      ...prevFormData,
      EMP_CODE: newEmpCode,
    }));
  };

  const title = "Student Details";

  return (
    <div className="background">
      <Header title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar selectedLogo={selectedLogo} />

        <motion.Container
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1, duration: 0.5 }}
          style={{ padding: 0 }}
        >
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Student Id"
                    name="EMP_CODE"
                    variant="outlined"
                    value={empCode}
                    onChange={handleChangeCurrentEmpCode}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="NAME"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="SURNAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel required>Gender</InputLabel>
                    <Select
                      label="Sex"
                      name="SEX"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Age"
                    name="AGE"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    value={phoneNumber}
                    error={phoneNumberError}
                    helperText={phoneNumberError ? "Invalid Entry." : ""}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Aadhar Number"
                    name="AADHAR_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    error={AadharNumberError}
                    helperText={AadharNumberError ? "Invalid Entry." : ""}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian Name"
                    name="GUARDIAN_NAME"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian PhNo."
                    name="GUARDIAN_PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    error={GphoneNumberError}
                    helperText={GphoneNumberError ? "Invalid Entry." : ""}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Relation</InputLabel>
                    <Select
                      label="Relation"
                      name="RELATIONSHIP"
                      onChange={handleChange}
                    >
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Self">Self</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Enrollment</InputLabel>
                    <Select
                      label="ENROLLMENT"
                      name="ENROLLMENT"
                      onChange={handleChange}
                    >
                      <MenuItem value="YES">YES</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel required>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active</MenuItem>
                      <MenuItem value="Pause">Pause</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {showDates && (
                  <>
                    <Grid item xs={11} sm={5} md={4} lg={2}>
                      <TextField
                        fullWidth
                        label="Pause Start"
                        name="PAUSE_START_DATE"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={11} sm={5} md={4} lg={2}>
                      <TextField
                        fullWidth
                        label="Pause End"
                        name="PAUSE_END_DATE"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={11} sm={5} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="Comments"
                    name="COMMENTS"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                {/* ////////////////////////////////////////////////////////////////// */}
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Class Information
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="category"
                    name="CATEGORY"
                    options={categoryData}
                    value={formData.CATEGORY}
                    onChange={(event, newValue) =>
                      handleChangeCategory(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Category" required />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="package-name"
                    options={packageName}
                    onChange={(event, newValue) => {
                      handleChangePackage(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formData.PACKAGE_NAME}
                        label="Package Name"
                        required
                      />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={weekDays}
                    name="WEEK_DAYS"
                    value={formData.WEEK_DAYS}
                    onChange={handleChangeWeekDay}
                    renderInput={(params) => (
                      <TextField {...params} label="Week Days" required />
                    )}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Sport Type"
                    name="SPORT_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.SPORT_TYPE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Duration"
                    name="DURATION"
                    variant="outlined"
                    value={formData.DURATION}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="PRICE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.PRICE}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Discount"
                    name="DISCOUNT"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.DISCOUNT}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Total Amount"
                    name="TOTAL_AMOUNT"
                    variant="outlined"
                    value={formData.TOTAL_AMOUNT}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Renewed</InputLabel>
                    <Select
                      label="Renewed"
                      name="RENEWED"
                      onChange={handleChange}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Time Slot</InputLabel>
                    <Select
                      label="Time Slot"
                      fullWidth
                      value={formData.TIME_SLOT}
                      onChange={handleChange}
                      name="TIME_SLOT"
                      variant="outlined"
                      required
                    >
                      {TIME_SLOT.map((slot, index) => (
                        <MenuItem key={index} value={slot}>
                          {slot}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="End Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    value={formData.END_DATE} // Bind the END_DATE state variable
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: "20%", backgroundColor: "#306ef3" }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </motion.Container>
      </div>
    </div>
  );
};

export default StudentData;
