import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";

import axios from "axios";
import SideBar1 from "../Components/SideBar1";
import Header1 from "../Components/Header1";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Products1 = () => {
  // preparation of useState , declare the variables
  const [formData, setFormData] = useState({
    PACKAGE_TYPE: "",
    TIME_SLOT: "",
    DURATION: "",
    PRICE: "",
    SPORT_TYPE: "Badminton",
    STATUS: "",
  });

  // update the input values to the variable
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const TIME_SLOT_MAP = {
    "5:30 - 7:00 AM": "6 - 7 AM",
    "4:00 - 5:30 PM": "4:00 - 5:30 PM",
    "5:00 - 6:30 PM": "5:00 - 6:30 PM",
    "5:30 - 7:00 PM": "5:30 - 7:00 PM",
  };

  // providing the api to insert the data into database , pass the values to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_BASE_URL}/sky_package`,
        formData
      );
      console.log(response.data);
      console.log("successfully inserted");
      window.alert("successfully submitted");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      window.alert("unable to submit");
    }
  };

  // Reverse map for displaying the correct label
  const TIME_SLOT_DISPLAY_MAP = Object.fromEntries(
    Object.entries(TIME_SLOT_MAP).map(([display, value]) => [value, display])
  );

  const getTimeSlots = () => {
    if (
      formData.PACKAGE_TYPE === "MEMBERSHIP" ||
      formData.PACKAGE_TYPE === "FULL COURT"
    ) {
      return TIME_SLOT;
    } else if (formData.PACKAGE_TYPE === "COACHING") {
      return Object.keys(TIME_SLOT_MAP);
    }
    return [];
  };

  const TIME_SLOT = [
    "5 - 6 AM",
    "6 - 7 AM",
    "7 - 8 AM",
    "8 - 9 AM",
    "9 - 10 AM",
    "7 - 8 PM",
    "8 - 9 PM",
    "9 - 10 PM",
  ];

  const PACKAGE_OPTIONS = ["MEMBERSHIP", "FULL COURT", "COACHING", "BULK"];

  const title = "Package Details";

  // start of the creation of UI
  return (
    <div className="background">
      <Header1 title={title} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#eae7eb",
        }}
      >
        <SideBar1 />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Package Details
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Package Type</InputLabel>
                    <Select
                      label="Package Type"
                      fullWidth
                      value={formData.PACKAGE_TYPE}
                      onChange={handleChange}
                      name="PACKAGE_TYPE"
                      variant="outlined"
                    >
                      {PACKAGE_OPTIONS.map((packageOption, index) => (
                        <MenuItem key={index} value={packageOption}>
                          {packageOption}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Time Slot</InputLabel>
                    <Select
                      label="Time Slot"
                      fullWidth
                      value={
                        TIME_SLOT_DISPLAY_MAP[formData.TIME_SLOT] ||
                        formData.TIME_SLOT
                      }
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        const internalValue =
                          TIME_SLOT_MAP[selectedValue] || selectedValue;
                        handleChange({
                          target: { name: "TIME_SLOT", value: internalValue },
                        });
                      }}
                      name="TIME_SLOT"
                      variant="outlined"
                    >
                      {getTimeSlots().map((slot, index) => (
                        <MenuItem key={index} value={slot}>
                          {slot}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Duration</InputLabel>
                    <Select
                      label="Duration"
                      name="DURATION"
                      onChange={handleChange}
                    >
                      <MenuItem value="1">1 Month</MenuItem>
                      <MenuItem value="6">6 Months</MenuItem>
                      <MenuItem value="12">12 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="PRICE"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Sport Type"
                    name="SPORT_TYPE"
                    variant="outlined"
                    value={formData.SPORT_TYPE}
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      onChange={handleChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <br />

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: "20%", backgroundColor: "#306ef3" }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Products1;
